import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  headerAppRoot: {
    width: "100%",
    position: "fixed",
    top: 0,
    zIndex: 50,
    height: "3.5rem",
  },
  headerAppContainer: {
    "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    "--tw-bg-opacity": 1,
    "--tw-border-opacity": 1,
    boxShadow: "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    backgroundColor: "rgba(255, 255, 255, var(--tw-bg-opacity))",
    borderColor: "rgba(229, 231, 235, var(--tw-border-opacity))",
    borderBottomWidth: "1px",
  },
  headerApp: {
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: "1rem",
    alignItems: "center",
    display: "inline-flex",
  },
  headerBurgerIcon: {
    fontSize: "1.25rem",
  },
  headerBrandLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  },
  headerAvatarIcon: {
    marginLeft: "auto",
    marginRight: "8px",
  },
  bodyAppRoot: {
    "--tw-bg-opacity": 1,
    backgroundColor: "rgba(255, 255, 255, var(--tw-bg-opacity))",
    marginTop: "1rem",
    position: "relative",
  },
  menuAppRoot: {
    "--tw-border-opacity": 1,
    position: "fixed",
    borderColor: "rgba(229, 231, 235, var(--tw-border-opacity))",
    borderRightWidth: 1,
    height: "100%",
    border: "2px solid whitesmoke",
  },
  menuAppContainer: {
    "--tw-text-opacity": 1,
    color: "rgba(107, 114, 128, var(--tw-text-opacity))",
    flexDirection: "column",
    display: "flex",
    width: "100%",
  },
  menuAppList: {
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    paddingLeft: 0,
    flexDirection: "column",
    flexGrow: 1,
    display: "flex",
    "& li": {
      listStyle: "none",
      margin: 0,
      padding: 0,
    }
  },
  menuAppItem: {
    "--tw-text-opacity": 1,
    "--tw-bg-opacity": 1,
    "--tw-border-opacity": 1,
    color: '#6B6B6B',
    borderColor: "rgba(139, 92, 246, var(--tw-border-opacity))",
    borderLeftWidth: "4px",
    cursor: "pointer",
    flexGrow: 1,
  },
  menuAppItemSelected: {
    color: "rgba(139, 92, 246, var(--tw-text-opacity))",
    textDecoration: "underline",
  },
  menuAppItemIcon: {
    margin: "8px",
    width: "20px",
    textAlign: "center",
  },
  contentAppRoot: {
    position: "absolute",
    top: "3rem",
    margin: '8px',
  },
}));
