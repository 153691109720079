import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";

const Card = ({
  backgroundColor,
  title,
  subtitle,
  description,
  value,
  onClick,
}: any) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} style={{ backgroundColor }} onClick={onClick}>
      <Box>
        <Typography variant="h6" color="white">
          {title}
        </Typography>
        <Typography color="white">
          {description}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h4"  textAlign="right" color="white">
          {value}
        </Typography>
        <Typography fontSize={15} textAlign="center" color="white">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default Card;
