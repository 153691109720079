import React, { useEffect, useState } from 'react';
import { Box, Snackbar as MuiSnackbar, Alert } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import useStyles from './styles';
import service from './service';

export interface BaseSnackbarStateProps {
  message: string,
  type: AlertColor,
  milliseconds: number,
}

interface Props {
  state: BaseSnackbarStateProps,
}
const BaseSnackbar = ({ state }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [, setTimerId] = useState<any>(null);
  const alertIcon = state.type === 'error' ? <CancelOutlinedIcon fontSize="inherit" /> : null;

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (state && state.message !== '') {
      setOpen(true);
      setTimerId((prev: any) => {
        clearTimeout(prev);
        return setTimeout(() => {
          setOpen(() => false);
        }, state.milliseconds);
      });
    }
  }, [state]);

  return (
    <Box className={classes.root}>
      <MuiSnackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={handleClose}
          variant={state.type === 'warning' ? "standard" : "outlined"}
          elevation={6}
          severity={state.type}
          icon={alertIcon}
        >
          {state.message}
        </Alert>
      </MuiSnackbar>
    </Box>
  );
};

const Snackbar = () => {
  const initialState = {
    message: '',
    type: 'success',
    milliseconds: 5000,
  } as BaseSnackbarStateProps;
  const [state, setState] = useState<BaseSnackbarStateProps>(initialState);

  const handleUpdate = () => setState(() => service.get());

  useEffect(() => {
    const subscription = service.subscribe(handleUpdate);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return state && <BaseSnackbar state={state} />;
};

export default Snackbar;
