import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  root: {
    border: "1px solid grey",
    borderRadius: "4px",
    cursor: "pointer",
  },
  body: {
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    display: "inline-flex",
    padding: "16px",
    paddingBottom: "8px",
  },
  footer: {
    height: "16px",
  },
}));
