import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  root: {
    width: "100%",
    minHeight: 'auto',
    marginBottom: "8px",
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  header: {
    backgroundColor: "#F5F5F5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerColumnLeft: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "8px",
    alignItems: "center",
  },
  headerColumnRight: {
    paddingRight: "8px",
  },
  title: {
    paddingTop: "8px",
    paddingBottom: "8px",
    color: "#000000",
    fontSize: "1.0rem",
    fontWeight: "bold",
  },
}));
