import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "../../../../app/components/theme/Container";
import { CountrySelect, SponsorTypeSelect, TextField } from "../../../../app/config/controls";
import { SponsorLike } from "../../../../app/interfaces";

const SponsorTab = ({ sponsor, onChange }: { sponsor: SponsorLike, onChange: (event: any) =>  void }) => (
  <Box padding="16px">
    <Container title="Cuenta Promotor">
      <Grid container spacing={2} style={{ padding: 16 }}>
        <Grid item xs={12}>
          <TextField
            value={sponsor.fullname}
            label="Nombre"
            name="fullname"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={sponsor.email}
            label="Correo"
            name="email"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={sponsor.password}
            label="Contraseña"
            type="password"
            name="password"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <SponsorTypeSelect
            value={sponsor.type}
            label="Tipo de Promotor"
            name="type"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CountrySelect
            value={sponsor.country}
            label="País"
            name="country"
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default SponsorTab;
