import React, { useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, TextField, AccountTypeSelect } from "../../../../../app/config/controls";
import api from "../../../api";

interface Props {
  open: boolean;
  form?: any;
  accountId: string;
  onClose: () => void;
  onAddSubAccount?: (subAccount: any) => void;
  onEditSubAccount?: (subAccount: any) => void;
}
const SubAccountDialog = ({
  open,
  form,
  accountId,
  onClose,
  onAddSubAccount,
  onEditSubAccount,
}: Props) => {
  const formik = useFormik({
    initialValues: {
      id: "",
      email: "",
      fullname: "",
      accountType: "",
    },
    validationSchema: yup.object({
      email: yup.string().email("Formato de correo invalido").required("Este campo es requerido"),
      fullname: yup.string().required("Este campo es requerido"),
      accountType: yup.number().min(1).max(3).required("Este campo es requerido"),
    }),
    onSubmit: (values) => {
      if (values.id) {
        api.subAccounts.edit(values).then((subAccount: any) => {
          if (onEditSubAccount) onEditSubAccount(subAccount);
          onClose();
        })
      } else {
        api.subAccounts.add(accountId, values).then((subAccount: any) => {
          if (onAddSubAccount) onAddSubAccount(subAccount);
          onClose();
        });
      }
    },
  });

  useEffect(() => {
    if (open) {
      formik.resetForm();
      if (form) {
        formik.setValues(form);
      }
    }
  }, [open, form]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Agregar Subcuenta</DialogTitle>
      <DialogContent>
        <Box padding="16px">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Correo"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  error={formik.errors.email && formik.touched.email}
                  helperText={
                    formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nombre"
                  name="fullname"
                  value={formik.values.fullname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  error={formik.errors.fullname && formik.touched.fullname}
                  helperText={
                    formik.errors.fullname && formik.touched.fullname
                      ? formik.errors.fullname
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AccountTypeSelect
                  label="Tipo de cuenta"
                  name="accountType"
                  value={formik.values.accountType}
                  onChange={formik.handleChange}
                  required
                  error={formik.errors.accountType && formik.touched.accountType}
                  helperText={
                    formik.errors.accountType && formik.touched.accountType
                      ? formik.errors.accountType
                      : null
                  }
                />
              </Grid>
            </Grid>
            <Box width="100%" paddingTop="16px" textAlign="right">
              <Button type="submit" disabled={formik.isSubmitting}>
                Guardar
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SubAccountDialog;
