import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import SnackbarService from "../../../../app/components/theme/Snackbar/service";
import ConfirmService from "../../../../app/components/theme/Confirm/service";
import { Button, Checkbox } from "../../../../app/config/controls";
import { Table, Pagination } from "../../../../app/config/tables";
import Container from "../../../../app/components/theme/Container";
import { CouponLike } from "../../../../app/interfaces";
import CouponSearchDialog from "./CouponSearchDialog";
import config from "./config";
import api from "../../api";

interface Props {
  accountId: string,
  coupons: Array<CouponLike>,
  onAddCoupon: any,
  onDeleteCoupon: any,
}
const CouponsTab = ({
  accountId, coupons, onAddCoupon, onDeleteCoupon,
}: Props) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [showAddCouponDialog, setShowAddCouponDialog] = useState<boolean>(false);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddClick = () => {
    setShowAddCouponDialog(true);
  };

  const handleCouponSearchDialogClose = () => {
    setShowAddCouponDialog(false);
  };

  const handleSearchCoupon = (couponStr: string) => {
    api.coupon.search(couponStr.trim()).then((coupons: any[]) => {
      if (coupons.length === 0) {
        SnackbarService.show('No se ha encontrado ningun cupon', 'info');
      }
      for (let i = 0; i < coupons.length; i++) {
        const coupon = coupons[i];
        api.coupon.add(accountId, coupon.id).then(() => {
          onAddCoupon(coupon);
        });
      }
    });
  };

  const handleDeleteClick = (row: any) => {
    const callback = () => api.coupon.delete(accountId, row.id).then(onDeleteCoupon);
    ConfirmService.show(
      "Eliminar",
      (
        <Box>
          <Typography style={{ fontWeight: "bold" }}>
            Estas seguro que deseas eliminar este cupon "{row.code}"?
          </Typography>
        </Box>
      ),
      callback,
    );
  };

  return (
    <Box width="100%" display="inline-flex" justifyContent="space-between">
      <Container
        title="Cupones"
        style={{ margin: 16 }}
        headerRightComponent={(
          <Button onClick={handleAddClick}>
            Agregar
          </Button>
        )}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ width: 80  }} />
                {config.columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>
                        <IconButton color="error" aria-label="email" onClick={() => handleDeleteClick(row)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      {config.columns.map((column: any) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : column.type === 'percentage'
                                ? `${value} %` : column.type === 'boolean'
                                  ? (<Checkbox checked={value} />) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <Pagination
            count={coupons.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <CouponSearchDialog
          open={showAddCouponDialog}
          onClose={handleCouponSearchDialogClose}
          onSearchCoupon={handleSearchCoupon}
        />
      </Container>
    </Box>
  );
};

export default CouponsTab;
