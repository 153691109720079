import axios from "axios";
import BlockUIService from "../../../app/components/theme/BlockUI/service";
import SnackbarService from "../../../app/components/theme/Snackbar/service";

const { REACT_APP_API_LOCATION } = process.env;

const api = {
  load: async (data: any) => {
    BlockUIService.show();
    try {
      const response = await axios.post(`${REACT_APP_API_LOCATION}/Admin/DashboardSponsorsSummary`, data);
      return response.data;
    } catch (e: any) {
      const message = e?.response?.data?.error?.message;
      await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
      throw e;
    } finally {
      BlockUIService.hide();
    }
  },
};

export default api;
