import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Highcharts from "highcharts";
import Chart from "highcharts-react-official";
import { MonthPicker, SponsorsSelect } from "../../app/config/controls";
import { ReactComponent as Ticket } from "../../assets/svg/ticket.svg";
import { ReactComponent as Sales } from "../../assets/svg/sales.svg";
import { ReactComponent as Sponsors } from "../../assets/svg/sponsors.svg";
import { Table } from "../../app/config/tables";
import History from "./components/History";
import Card from "./components/Card";
import api from "./api";

const SponsorManagement = () => {
  const [state, setState] = useState<any>({
    commissions: { total: 0, totalSales: 0, payments: [] },
    paidCoupons: { total: 0, payments: [] },
    registeredSponsors: { total: 0, sponsors: [] },
  });
  const [startDate, setStartDate] = useState<any>(new Date());
  const [sponsors, setSponsors] = useState<any[]>([]);
  const [showPayedCoupons, setShowPayedCoupons] = useState<boolean>(false);
  const [showCommissions, setShowCommissions] = useState<boolean>(false);
  const [showRegisteredSponsors, setShowRegisteredSponsors] = useState<boolean>(false);

  const handleStartDateChange = (event: any) => {
    setStartDate(new Date(event.target.value));
  };

  const handleSponsorsChange = (event: any) => {
    setSponsors(event.target.value);
  };

  const getLightColor = () => {
    const generateRandomHex: any = (math: any, string: string, count: number) => string[math.floor(math.random() * string.length)] + (count && generateRandomHex(math, string, count - 1));

    return `#${generateRandomHex(Math, 'ABCDEF', 4)}`;
  };

  useEffect(() => {
    const data = { sponsorsId: sponsors, date: moment(startDate).format("YYYY-MM") };
    api.load(data).then(setState);
  }, [startDate, sponsors]);

  return (
    <Paper sx={{ padding: '8px' }}>
      <Grid container spacing={2} style={{ marginBottom: "16px" }}>
        <Grid item xs={12}>
          <Typography fontWeight="bold" fontSize="24px" color="grey">
            Promotores
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: "8px" }}>
        <Grid item xs={12} md={3}>
          <MonthPicker label="Filtrar por Mes" name="filter" value={startDate} onChange={handleStartDateChange} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SponsorsSelect placeholder="Filtrar por..." value={sponsors} onChange={handleSponsorsChange} />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: "8px" }}>
        <Grid item xs={12} md={4}>
          <Card
            color="#8B5CF5"
            title={state.paidCoupons.total}
            description="Pagos contabilizados en el periodo"
            icon={Ticket}
            onClick={() => setShowPayedCoupons(true)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            color="#FF6781"
            title={`$ ${state.commissions.total.toFixed(2)}`}
            description="Comisiones por pagar"
            icon={Sales}
            onClick={() => setShowCommissions(true)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            color="#FF9F80"
            title={state.registeredSponsors.total}
            description="Promotores registrados"
            icon={Sponsors}
            onClick={() => setShowRegisteredSponsors(true)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Chart
            highcharts={Highcharts}
            options={{
              chart: {
                renderTo: "container",
                type: "pie",
              },
              title: {
                text: "Uso de cupones",
                style: {
                  color: "grey",
                  fontSize: "24px",
                },
              },
              plotOptions: {
                pie: {
                  shadow: false,
                },
                series: {
                  enableMouseTracking: true,
                  borderWidth: 0,
                  dataLabels: {
                    enabled: true,
                  },
                },
              },
              series: [{
                name: "Coupons",
                data: state.paidCoupons.payments.filter((payment: any) => payment.coupon).reduce((acc: any[], curr: any) => {
                  const coupon = acc.find((a) => a.name === curr.coupon);
                  if (coupon) {
                    return acc.map((a) => a.name === coupon.name ? ({ ...a, y: a.y + 1 }) : a);
                  }
                  return [...acc, { name: curr.coupon, y: 1, color: getLightColor() }];
                }, []),
                size: "100%",
                innerSize: "25%",
                showInLegend: false,
              }],
            }}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Chart
            highcharts={Highcharts}
            options={{
              chart: {
                renderTo: "container",
                type: "column",
              },
              title: {
                text: "Cupones utilizados por Promotor",
                style: {
                  color: "grey",
                  fontSize: "24px",
                },
              },
              subtitle: {
                useHTML: true,
                text: "<div style='width: 100%; display: inline-flex;'><div style='width: 35px;\n" +
                  "    height: 15px;\n" +
                  "    background-color: rgb(255, 182, 195);\n" +
                  "    margin-right: 8px;\n" +
                  "    margin-top: 2px;'></div><div>Uso de Cupones</div></div>",
              },
              xAxis: {
                type: "category"
              },
              yAxis: {
                max: state.paidCoupons.total,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                series: {
                  enableMouseTracking: false,
                  borderWidth: 0,
                  dataLabels: {
                    enabled: false,
                  },
                }
              },
              legend: {
                enabled: false,
              },
              series: [{
                name: 'Sponsors',
                data: state.paidCoupons.payments.filter((payment: any) => payment.coupon).reduce((acc: any[], curr: any) => {
                  const sponsor = acc.find((a) => a.name === curr.sponsorName);
                  if (sponsor) {
                    return acc.map((a) => a.name === curr.sponsorName ? ({ ...a, y: a.y + 1 }) : a);
                  }
                  return [...acc, { name: curr.sponsorName, y: 1, color: '#FFB6C3' }];
                }, []),
              }],
            }}
          />
        </Grid>
      </Grid>
      <History
        title="Pagos"
        open={showPayedCoupons}
        onClose={() => setShowPayedCoupons(false)}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ minWidth: 180 }}>Fecha de Pago</TableCell>
                <TableCell style={{ minWidth: 180 }}>Cliente</TableCell>
                <TableCell style={{ minWidth: 180, textAlign: "right" }}>Monto del Pago</TableCell>
                <TableCell style={{ minWidth: 180 }}>Cupon</TableCell>
                <TableCell style={{ minWidth: 180 }}>Genera comision a</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.paidCoupons.payments.map((row: any, index: number) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    {moment(row.paymentDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {row.client.fullname}
                  </TableCell>
                  <TableCell align="right">
                    {`$ ${row.amount}`}
                  </TableCell>
                  <TableCell>
                    {row.coupon || "No se uso cupon en este pago"}
                  </TableCell>
                  <TableCell>
                    {row.sponsorName}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </History>
      <History
        title="Comisiones"
        open={showCommissions}
        onClose={() => setShowCommissions(false)}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ minWidth: 180 }}>Fecha de Pago</TableCell>
                <TableCell style={{ minWidth: 180 }}>Cliente</TableCell>
                <TableCell style={{ minWidth: 180 }}>Monto del Pago</TableCell>
                <TableCell style={{ minWidth: 180 }}>Cupon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.commissions.payments.map((row: any, index: number) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    {moment(row.paymentDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {row.client.fullname}
                  </TableCell>
                  <TableCell align="right">
                    {`$ ${row.amount}`}
                  </TableCell>
                  <TableCell>
                    {row.sponsorName}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </History>
      <History
        title="Promotores"
        open={showRegisteredSponsors}
        onClose={() => setShowRegisteredSponsors(false)}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ minWidth: 180 }}>Fecha</TableCell>
                <TableCell style={{ minWidth: 180 }}>Email</TableCell>
                <TableCell style={{ minWidth: 180 }}>Nombre</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.registeredSponsors.sponsors.map((row: any, index: number) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    {moment(row.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {row.email}
                  </TableCell>
                  <TableCell>
                    {row.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </History>
    </Paper>
  );
};

export default SponsorManagement;
