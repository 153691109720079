import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add'
import Container from '../../../../app/components/theme/Container';
import {
  TextArea,
  TextField,
  NumberField,
  CountrySelect,
  CurrencySelect,
  TimezoneSelect,
  DatePicker,
} from '../../../../app/config/controls';

interface Props {
  businessInfo: {
    name: string,
    country: string,
    phone: string,
    address: string,
    logo: string,
    currency: string,
    policy: string,
    timezone: string,
    membershipEndDate: string,
  },
  onChange: any,
}
const BusinessInfoTab = ({ businessInfo, onChange }: Props) => {
  const phone = businessInfo.phone.split('|');
  const [lada, setLada] = useState<number>(parseFloat(phone[0]));
  const [number, setNumber] = useState<number>(parseFloat(phone[1]));

  const handleLadaChange = (event: any) => {
    setLada(event.target.value);
  };

  const handleNumberChange = (event: any) => {
    setNumber(event.target.value);
  };

  const handleBlur = () => {
    onChange({ target: { name: 'businessInfoPhone', value: `${lada}|${number}` } })
  };

  return (
    <Box padding="16px">
      <Container title="Información de Membresía">
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12}>
            <DatePicker
              label="Fecha de Vencimiento Membresia"
              value={businessInfo.membershipEndDate}
              name="businessInfoMembershipEndDate"
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Container>
      <Container title="Información de Negocio">
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={5}>
            <TextField
              value={businessInfo.name}
              name="businessInfoName"
              label="Nombre"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={2}>
            <CountrySelect
              value={businessInfo.country}
              name="businessInfoCountry"
              label="País"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={2}>
            <NumberField
              value={lada}
              label="Código de Area"
              onChange={handleLadaChange}
              onBlur={handleBlur}
              InputProps={{
                startAdornment: (
                  <AddIcon color="disabled" fontSize="small" />
                ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              value={number}
              label="Teléfono"
              onChange={handleNumberChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={4}>
            <CurrencySelect
              value={businessInfo.currency}
              label="Tipo de Moneda"
              name="businessInfoCurrency"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TimezoneSelect
              value={businessInfo.timezone}
              label="Zona Horaria"
              name="businessInfoTimezone"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4} />
        </Grid>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12}>
            <TextArea
              value={businessInfo.address}
              name="businessInfoAddress"
              label="Dirección"
              rows={4}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Container>
      <Container title="Información de Politicas">
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12}>
            <TextArea
              value={businessInfo.policy}
              name="businessInfoPolicy"
              label="Políticas de garantía"
              rows={4}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BusinessInfoTab;
