import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmService from "../../../../../app/components/theme/Confirm/service";
import { Table, Pagination } from "../../../../../app/config/tables";
import { accountTypesEnum } from "../../../../../app/constants";
import SubAccountDialog from "../SubAccountDialog";
import api from "../../../api";
import config from "./config";

interface Props {
  accountId: string;
  subAccounts: any[];
  onEditSubAccount: (subAccount: any) => void;
  onDeleteSubAccount: (subAccountId: string) => void;
}
const SubAccountsGrid = ({
  accountId,
  subAccounts,
  onEditSubAccount,
  onDeleteSubAccount,
}: Props) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [showSubAccountDialog, setShowSubAccountDialog] = useState<any>({
    open: false,
    form: {
      id: "",
      email: "",
      fullname: "",
      accountType: "",
    },
  });

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = (row: any) => {
    setShowSubAccountDialog({ open: true, form: row });
  };

  const handleDeleteClick = (row: any) => {
    const callback = () => api.subAccounts.delete(row.id).then(onDeleteSubAccount);
    ConfirmService.show(
      "Eliminar",
      (
        <Box>
          <Typography style={{ fontWeight: "bold" }}>
            Estas seguro que deseas eliminar la subcuenta de "{row.fullname}"?
          </Typography>
          <Box>
            <Typography style={{ color: "red" }}>
              Advertencia: Las ordenes asignadas a este usuario se DESASIGNARAN.
            </Typography>
          </Box>
        </Box>
      ),
      callback,
    );
  };

  const handleSubAccountDialogClose = () => {
    setShowSubAccountDialog({ open: false, form: { id: "", email: "", fullname: "", accountType: "" } });
  };

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Table>
          <TableHead>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell style={{ width: 80  }} />
              {config.columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {subAccounts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      <IconButton color="primary" aria-label="email" onClick={() => handleEditClick(row)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton color="error" aria-label="email" onClick={() => handleDeleteClick(row)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    {config.columns.map((column: any) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : column.type === 'accountType'
                              ? accountTypesEnum[value]
                              : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <Pagination
          count={subAccounts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <SubAccountDialog
        accountId={accountId}
        open={showSubAccountDialog.open}
        form={showSubAccountDialog.form}
        onClose={handleSubAccountDialogClose}
        onEditSubAccount={onEditSubAccount}
      />
    </>
  );
};

export default SubAccountsGrid;
