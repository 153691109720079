import Box from '@mui/material/Box';
import {ReactElement} from "react";

interface Props {
  children: ReactElement,
  value: number,
  index: number,
  style?: any,
  className?: string,
}
const TabPanel = ({
  children,
  value,
  index,
  style,
  className,
}: Props) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    className={className}
  >
    {value === index && (
      <Box style={style}>
        {children}
      </Box>
    )}
  </div>
);

export default TabPanel;
