import axios from "axios";
import BlockUIService from "../../theme/BlockUI/service";
import SnackbarService from "../../theme/Snackbar/service";
import { months } from "../../../constants";

const { REACT_APP_API_LOCATION } = process.env;

const ADMIN_SPONSOR_PAYMENTS_REQUEST_LIST = `${REACT_APP_API_LOCATION}/Admin/SponsorPaymentRequestList`;
const ADMIN_UPDATE_PAYMENT_REQUEST_STATUS = `${REACT_APP_API_LOCATION}/Admin/UpdatePaymentRequestStatus`;

const mapGetResponse = (payments = []) => payments.map((payment: any) => ({
  id: payment.id,
  name: `${months[payment.month - 1]} ${payment.year}`,
  status: payment.isPaid ? 'paid' : payment.isRejected ? 'rejected' : 'review',
  invoicePdf: payment.invoicePdf,
  evidences: {
    first: payment.firstEvidence,
    second: payment.secondEvidence,
  },
}));

const api = {
  load: async (params: any) => {
    BlockUIService.show();
    try {
      const response = await axios.get(ADMIN_SPONSOR_PAYMENTS_REQUEST_LIST, { params });
      return mapGetResponse(response.data.data);
    } catch (e: any) {
      const message = e?.response?.data?.error?.message;
      await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
      throw e;
    } finally {
      BlockUIService.hide();
    }
  },
  update: async (data: any) => {
    BlockUIService.show();
    try {
      await axios.post(ADMIN_UPDATE_PAYMENT_REQUEST_STATUS, data);
      await SnackbarService.show('La Solicitud de Pago se actualizó correctament', 'success');
    } catch (e: any) {
      const message = e?.response?.data?.error?.message;
      await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
      throw e;
    } finally {
      BlockUIService.hide();
    }
  },
};

export default api;
