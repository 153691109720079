import React from "react";
import {
  useStripe, useElements, Elements, CardElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "../../../../../app/config/controls";

// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CardForm = ({ onSubmit }: { onSubmit: (token: string) => void }) => {
  const stripe: any = useStripe();
  const elements: any = useElements();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { error, token } = await stripe.createToken(elements.getElement(CardElement));
    if (error) {
      return;
    }
    onSubmit(token.id);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <Box width="100%" textAlign="right" marginTop="16px">
        <Button type="submit" disabled={!stripe}>
          Guardar
        </Button>
      </Box>
    </form>
  );
};

const CardAddDialog = ({ open, onClose, onAddCard }: { open: boolean, onClose: () => void, onAddCard: (card: string) => void }) => {
  const handleSubmit = (token: string) => {
    onAddCard(token);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Agregar Tarjeta</DialogTitle>
      <DialogContent>
        <Elements stripe={stripePromise}>
          <CardForm onSubmit={handleSubmit} />
        </Elements>
      </DialogContent>
    </Dialog>
  );
};

export default CardAddDialog;
