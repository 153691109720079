export const colors = {
  root: '#8B5CF6',
};

const getColors = (page: string) => {
  let palette: any;

  switch(page) {
    default:
      palette = {
        primary: { main: '#8B5CF6', contrastText: '#FFFFFF' },
      }
      break;
  }

  return palette;
};

export default getColors;
