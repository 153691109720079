import React, { useRef, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";
import { SponsorLike, CouponLike } from "../../app/interfaces";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SponsorPayments from "../../app/components/SponsorPayments";
import Container from "../../app/components/theme/Container";
import TabPanel from "../../app/components/theme/TabPanel";
import CommissionTab from "./components/CommissionTab";
import { Button } from "../../app/config/controls";
import CouponsTab from "./components/CouponsTab";
import SponsorTab from "./components/SponsorTab";
import api from "./api";

const initialState: SponsorLike = {
  id: '',
  fullname: '',
  email: '',
  password: '',
  country: '',
  currency: '',
  bankName: '',
  bankAccount: '',
  paypalAccount: '',
  type: '',
  coupons: [],
  percent: 0,
};

const Sponsor = () => {
  const paymentsRef = useRef();
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [sponsor, setSponsor] = useState<SponsorLike>(initialState);
  const [changes, setChanges] = useState<string[]>([]);

  const handleTabChange = (event: any, newValue: number) => setTabIndex(newValue);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event: any) => {
    setChanges((prev: string[]) => {
      if (!prev.includes(event.target.name)) return [...prev, event.target.name];
      return prev;
    });
    setSponsor((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSave = () => {
    const patchFields = {};
    for (let i = 0; i < changes.length; i++) {
      const field: string = changes[i];
      // @ts-ignore
      patchFields[field] = sponsor[field];
    }
    if (id) {
      api.update(id, patchFields);
      setChanges([]);
    } else {
      api.create(patchFields);
      setChanges([]);
      history.push("/sponsors");
    }
  };

  const handleAddCoupon = (coupon: CouponLike) => {
    setSponsor((prev: SponsorLike) => ({
      ...prev,
      coupons: [...prev.coupons, coupon],
    }));
  };

  const handleDeleteCoupon = (id: string) => {
    setSponsor((prev: SponsorLike) => ({
      ...prev,
      coupons: prev.coupons.filter((c) => c.id !== id),
    }));
  };

  useEffect(() => {
    if (id) api.load(id).then(setSponsor);

    return () => {
      setSponsor(initialState);
    };
  }, [id]);

  return (
    <Container
      title={sponsor.fullname || 'Promotor'}
      headerLeftComponent={(
        <ChevronLeftIcon onClick={handleBack} style={{ cursor: "pointer" }} />
      )}
      headerRightComponent={(tabIndex === 0 || tabIndex === 1) && (
        <Button onClick={handleSave}>
          Guardar
        </Button>
      )}
    >
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          variant="scrollable"
        >
          <Tab tabIndex={0} label="Cuenta" />
          <Tab tabIndex={1} label="Comisiones" disabled={!id} />
          <Tab tabIndex={2} label="Cupones" disabled={!id} />
          <Tab tabIndex={3} label="Pagos" disabled={!id} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <SponsorTab sponsor={sponsor} onChange={handleChange} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <CommissionTab sponsor={sponsor} onChange={handleChange} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <CouponsTab
          sponsorId={sponsor.id}
          coupons={sponsor.coupons}
          onAddCoupon={handleAddCoupon}
          onDeleteCoupon={handleDeleteCoupon}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <Box padding="16px">
          <SponsorPayments
            ref={paymentsRef}
            sponsorId={sponsor.id}
            showAttachments
          />
        </Box>
      </TabPanel>
    </Container>
  );
};

export default Sponsor;
