import React, {
  createContext, useContext, useMemo, useReducer, useCallback,
} from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import getColors from './colors';

const DispatchContext = createContext<any>(null);

DispatchContext.displayName = 'ThemeDispatchContext';

const ThemeContext = (props: any) => {
  const { children, style } = props;

  const [themeOpts, setThemeOpts] = useReducer((state: any, action: any) => {
    switch (action.type) {
      case 'CHANGE':
        return { ...state, colors: action.payload.colors || 'DEFAULT' };
      default:
        throw new Error(`Unrecognized type ${action.type}`);
    }
  }, { page: 'DEFAULT' });

  const { colors } = themeOpts;

  const theme = useMemo(() => createTheme({ ...style, palette: { ...style.palete, ...getColors(colors) } }), [colors, style]);

  return (
    <ThemeProvider theme={theme}>
      <DispatchContext.Provider value={setThemeOpts}>
        {children}
      </DispatchContext.Provider>
    </ThemeProvider>
  );
};

export const useChangeTheme = () => {
  const dispatch: any = useContext(DispatchContext);

  return useCallback(
    (themeOpts: any) => dispatch({ type: 'CHANGE', payload: themeOpts })
    , [dispatch]);
};

export default ThemeContext;