import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  root: {
    "& .MuiAlert-icon": {
      alignItems: "center",
    },
    "& .MuiAlert-outlinedSuccess": {
      background: "#FFFFFF",
      border: 0,
      "& .MuiAlert-message": {
        color: "#000000",
      },
    },
    "& .MuiAlert-outlinedError": {
      background: "#FFFFFF",
      border: 0,
      "& .MuiAlert-message": {
        color: "#000000",
      },
    },
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
