import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useStyles from './styles';

interface Props {
  children: any,
  title: string,
  headerLeftComponent?: any,
  headerRightComponent?: any,
  style?: any,
}
const Container = (props: Props) => {
  const {
    children,
    title,
    headerLeftComponent,
    headerRightComponent,
    style,
  } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root} style={style}>
      <Box className={classes.header}>
        <Box className={classes.headerColumnLeft}>
          {headerLeftComponent}
          <Typography className={classes.title}>
            {title}
          </Typography>
        </Box>
        <Box className={classes.headerColumnRight}>
          {headerRightComponent}
        </Box>
      </Box>
      {children}
    </Paper>
  );
};

export default Container;
