const columns = [
  { id: 'code', label: 'Nombre', minWidth: 170 },
  { id: 'isPercent', label: 'Es Porcentage', minWidth: 170, type: 'boolean' },
  { id: 'amountOff', label: 'Cantidad', minWidth: 170 },
  { id: 'percentOff', label: 'Porcentage', minWidth: 170, type: 'percentage' },
];

const config = {
  columns,
};

export default config;
