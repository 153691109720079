import React from "react";
import MuiDialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";

const Dialog = ({ title, children, open, onClose }: { title: string, open: boolean, children: any, onClose: () => void }) => (
  <MuiDialog
    fullWidth
    maxWidth="lg"
    open={open}
    onClose={onClose}
  >
    <MuiDialogTitle>{title}</MuiDialogTitle>
    <MuiDialogContent>{children}</MuiDialogContent>
  </MuiDialog>
);

export default Dialog;
