import React, { useEffect, useState } from "react";
import {
  BrowserRouter, Switch, Route, Redirect, useHistory,
} from "react-router-dom";
import axios from "axios";

import BlockUI, { Backdrop } from "./app/components/theme/BlockUI";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { store, destroy } from "./app/store/user";
import Snackbar from "./app/components/theme/Snackbar";
import AppLayout from "./app/layouts/AppLayout";
import Confirm from "./app/components/theme/Confirm";
import ThemeContext from "./app/theme";

// FEATURES
import Login from "./features/Login";
import Accounts from "./features/Accounts";
import Account from "./features/Account";
import Sponsors from "./features/Sponsors";
import Sponsor from "./features/Sponsor";
import SponsorCommissions from "./features/SponsorCommissions";
import SponsorPaymentInformation from "./features/SponsorPaymentInformation";
import SponsorManagement from "./features/SponsorManagement";

// STYLES
import "./App.css";

const { REACT_APP_API_LOCATION } = process.env;

const theme = {
  palette: {
    primary: { main: '#8B5CF6', contrastText: '#FFFFFF' },
  },
  overrides: {},
};

const AdminRoutes = () => (
  <Switch>
    <AppLayout>
      <Route exact path="/app" render={() => <Redirect to="accounts" />} />
      <Route path="/accounts" component={Accounts} />
      <Route path="/account/:id" component={Account} />
      <Route path="/sponsors" component={Sponsors} />
      <Route path="/sponsor/:id" component={Sponsor} />
      <Route path="/sponsor-create" component={Sponsor} />
      <Route path="/sponsor-management" component={SponsorManagement} />
    </AppLayout>
  </Switch>
);

const SponsorRoutes = () => (
  <Switch>
    <AppLayout>
      <Route exact path="/app" render={() => <Redirect to="sponsor-commissions" />} />
      <Route exact path="/sponsor-commissions" component={SponsorCommissions} />
      <Route exact path="/my-payment-information" component={SponsorPaymentInformation} />
    </AppLayout>
  </Switch>
);

const PrivateNavigation = ({
  adminRoutes: AdminComponent,
  sponsorRoutes: SponsorComponent,
  ...props
}: any) => {
  const { path } = props;
  const history = useHistory();
  const { rol } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [blockUI, setBlockUI] = useState(false);

  const isLogged = () => {
    setBlockUI(true);
    axios.get(`${REACT_APP_API_LOCATION}/Admin/Current`)
      .then((response) => {
        dispatch(store({
          id: response.data.id,
          name: response.data.username,
          rol: response.data.role.name,
          menus: response.data.menus,
          sponsorId: response.data.sponsorId,
        }));
      })
      .catch (() => {
        dispatch(destroy());
        history.push("/");
      })
      .finally(() => {
        setBlockUI(false);
      })
  };

  useEffect(() => {
    isLogged();
  }, []);

  return (
    <React.Suspense fallback={<Backdrop open={blockUI} />}>
      {blockUI && <Backdrop open={blockUI} />}
      {rol === "admin" && (<Route path={path} render={() => <AdminComponent />} />)}
      {rol === "sponsor" && (<Route path={path} render={() => <SponsorComponent />} />)}
    </React.Suspense>
  );
};

const Navigation = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <PrivateNavigation
      path="/"
      adminRoutes={AdminRoutes}
      sponsorRoutes={SponsorRoutes}
    />
  </Switch>
);

function App() {
  return (
    <ThemeContext style={theme}>
      <BrowserRouter>
        <BlockUI name="root" />
        <Snackbar />
        <Confirm />
        <Navigation />
      </BrowserRouter>
    </ThemeContext>
  );
}

export default App;
