import React, { useRef, useEffect, useState } from "react";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import SponsorPayments from "../../app/components/SponsorPayments";
import { Button, TextField } from "../../app/config/controls";
import PaymentRequest from "./components/PaymentRequest";
import { useAppSelector } from "../../app/hooks";
import api from "./api";

const SponsorPaymentInformation = () => {
  const paymentsRef = useRef<any>({ current: { reload: () => {} } });
  const { id, sponsorId, name } = useAppSelector((state) => state.user);
  const [showPaymentRequestDialog, setShowPaymentRequestDialog] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      bankName: "",
      bankAccount: "",
      paypalAccount: "",
    },
    validationSchema: yup.object({
      bankName: yup.string(),
      bankAccount: yup.string(),
      paypalAccount: yup.string(),
    }),
    onSubmit: () => {},
  });

  const handleSave = () => {
    api.update(sponsorId, formik.values);
  };

  const handlePaymentRequestCreate = () => {
    setShowPaymentRequestDialog(false);
    paymentsRef.current.reload();
  };

  useEffect(() => {
    api.load(sponsorId).then((sponsor: any) => {
      formik.setValues({
        bankName: sponsor.bankName || "",
        bankAccount: sponsor.bankAccount || "",
        paypalAccount: sponsor.paypalAccount || "",
      });
    });
  }, [sponsorId]);

  return (
    <Paper sx={{ padding: '8px' }}>
      <Grid container spacing={2} style={{ marginBottom: "8px", textAlign: "center" }}>
        <Grid item xs={12} padding="8px 0 !important">
          <Typography fontWeight="bold" fontSize="24px" color="grey">
            {moment(new Date()).format("DD/MM/YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={12} padding="0 !important">
          <Typography fontWeight="bold" fontSize="24px" color="grey">
            Saludos, {name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: "8px" }}>
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={12}>
            <Typography fontWeight="bold" fontSize="24px" color="grey">
              Mi Información de Pagos
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={4}>
            <TextField
              name="bankName"
              label="Nombre del Banco"
              value={formik.values.bankName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              name="bankAccount"
              label="Cuenta Bancaria"
              value={formik.values.bankAccount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={4}>
            <TextField
              name="paypalAccount"
              label="Cuenta PayPal"
              value={formik.values.paypalAccount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={8}>
            <Box width="100%" textAlign="right">
              <Button onClick={handleSave}>Guardar</Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ margin: "16px 0" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SponsorPayments
            ref={paymentsRef}
            action={(
              <Button onClick={() => setShowPaymentRequestDialog(true)}>
                Solicitar Nuevo Pago
              </Button>
            )}
          />
        </Grid>
      </Grid>
      <PaymentRequest
        accountId={id}
        open={showPaymentRequestDialog}
        onClose={() => setShowPaymentRequestDialog(false)}
        onPaymentRequestCreate={handlePaymentRequestCreate}
      />
    </Paper>
  );
};

export default SponsorPaymentInformation;
