const columns = [
  { id: 'name', label: 'Nombre', width: 200 },
  { id: 'email', label: 'Correo', width: 200 },
  { id: 'coupons', label: 'Cupones', minWidth: 200 },
];

const config = {
  columns,
};

export default config;
