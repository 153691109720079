import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "../../../../app/components/theme/Container";
import { TextField } from "../../../../app/config/controls";
import { SponsorLike } from "../../../../app/interfaces";

const CommissionTab = ({ sponsor, onChange }: { sponsor: SponsorLike, onChange: (event: any) => void }) => {
  const unsupportedChars = new Set(['e', 'E', '-']);

  const handleKeyPress = (event: any) => {
    if (unsupportedChars.has(event.key)) event.preventDefault();
  };

  const handleChange = (event: any) => {
    if (!event.target.value || event.target.value < 0 || event.target.value > 100) {
      onChange({ ...event, target: { ...event.target, value: 0 } });
      return;
    }
    onChange(event);
  };

  return (
    <Box padding="16px">
      <Container title="Comisiones">
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12}>
            <TextField
              value={sponsor.percent}
              label="El Promotor tendra este porcentage de comision por 12 meses"
              type="number"
              name="percent"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              inputProps={{
                min: 0,
                max: 100,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CommissionTab;
