import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, MonthPicker, TextField } from "../../../../app/config/controls";
import api from "./api";

const PaymentRequest = ({
  accountId, open, onClose, onPaymentRequestCreate
}: {
  accountId: string | null,
  open: boolean,
  onClose: () => void,
  onPaymentRequestCreate: (payment: any) => void,
}) => {
  const [files, setFiles] = useState<any>({
    invoicePdf: undefined,
    invoiceXml: undefined,
    firstEvidence: undefined,
    secondEvidence: undefined,
  });
  const formik = useFormik({
    initialValues: {
      dateToPay: moment(new Date(new Date().getFullYear(), new Date().getMonth(), 0)).format("YYYY-MM"),
      invoicePdf: "",
      invoiceXml: "",
      firstEvidence: "",
      secondEvidence: "",
    } as any,
    validationSchema: yup.object({
      dateToPay: yup.string().required("Este campo es requerido"),
      invoicePdf: yup.string().required("Este campo es requerido"),
      invoiceXml: yup.string(),
      firstEvidence: yup.string().required("Este campo es requerido"),
      secondEvidence: yup.string().required("Este campo es requerido"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      api.createPaymentRequest(accountId, {
        dateToPay: values.dateToPay, files,
      }).then((payment: any) => {
        onPaymentRequestCreate(payment);
        setSubmitting(false);
      });
    },
  });

  const handleFileChange = (event: any) => {
    formik.handleChange(event);
    setFiles((prev: any) => ({ ...prev, [event.target.name]: event.target.files.length > 0
        ? event.target.files[0] : undefined }));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Solicitud de Pago</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography fontSize="16px" color="grey">
                Para solicitar un nuevo pago, debe subir la Invoice correspondiente
                al mes y las evidencias del seguimiento por redes sociales.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Box textAlign="center" fontSize="24px" fontWeight="bold" border="2px solid grey" color="grey">
                AQUI VA LA INFORMACION DE SAMII PARA FACTURAR**
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} item xs={12} style={{ marginBottom: "8px" }}>
                  <Grid item xs={4}>
                    <MonthPicker
                      name="dateToPay"
                      label="Seleccione el Mes de Pago" variant="standard"
                      value={formik.values.dateToPay}
                      onChange={formik.handleChange}
                      maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), 0)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="invoicePdf"
                      type="file"
                      variant="standard"
                      label="Seleccionar Invoice"
                      value={formik.values.invoicePdf}
                      onChange={handleFileChange}
                      required
                      error={formik.errors.invoicePdf && formik.touched.invoicePdf}
                      helperText={
                        formik.errors.invoicePdf && formik.touched.invoicePdf
                          ? formik.errors.invoicePdf
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="invoiceXml"
                      type="file"
                      variant="standard"
                      label="Seleccionar Invoice(XML)"
                      value={formik.values.invoiceXml}
                      onChange={handleFileChange}
                      error={formik.errors.invoiceXml && formik.touched.invoiceXml}
                      helperText={
                        formik.errors.invoiceXml && formik.touched.invoiceXml
                          ? formik.errors.invoiceXml
                          : null
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      name="firstEvidence"
                      type="file"
                      variant="standard"
                      label="Seleccionar Evidencia #1"
                      required
                      value={formik.values.firstEvidence}
                      onChange={handleFileChange}
                      error={formik.errors.firstEvidence && formik.touched.firstEvidence}
                      helperText={
                        formik.errors.firstEvidence && formik.touched.firstEvidence
                          ? formik.errors.firstEvidence
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="secondEvidence"
                      type="file"
                      variant="standard"
                      label="Seleccionar Evidencia #2"
                      required
                      value={formik.values.secondEvidence}
                      onChange={handleFileChange}
                      error={formik.errors.secondEvidence && formik.touched.secondEvidence}
                      helperText={
                        formik.errors.secondEvidence && formik.touched.secondEvidence
                          ? formik.errors.secondEvidence
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={4} />
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={12}>
                    <Box textAlign="right">
                      <Button type="submit" disabled={formik.isSubmitting}>
                        Guardar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentRequest;
