import React from "react";
import { Box } from "@mui/material";

interface Props {
  children: any;
}
const PublicLayout = ({ children }: Props) => {
  return <Box >{children}</Box>;
};

export default PublicLayout;
