import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmService from "../../../../app/components/theme/Confirm/service";
import { Table, Pagination } from "../../../../app/config/tables";
import Container from "../../../../app/components/theme/Container";
import { Button } from "../../../../app/config/controls";
import { CardLike } from "../../../../app/interfaces";
import { months } from "../../../../app/constants";
import CardAddDialog from "./CardAddDialog";
import config from "./config";
import api from "../../api";

interface Props {
  accountId: string,
  cards: Array<CardLike>,
  onAddCard: any,
  onDeleteCard: any,
}
const CardsTab = ({
  accountId, cards, onAddCard, onDeleteCard,
}: Props) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [showCardAddDialog, setShowCardAddDialog] = useState<boolean>(false);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddClick = () => {
    setShowCardAddDialog(true);
  };

  const handleCardAddDialogClose = () => {
    setShowCardAddDialog(false);
  };

  const handleAddCard = (token: string) => {
    api.card.add(accountId, token).then(onAddCard);
  };

  const handleDeleteClick = (row: any) => {
    const callback = () => api.card.delete(row.id).then(onDeleteCard);
    ConfirmService.show(
      "Eliminar",
      (
        <Box>
          <Typography style={{ fontWeight: "bold" }}>
            Estas seguro que deseas eliminar la tarjeta "{row.brand}" con terminación "{row.last4}"?
          </Typography>
        </Box>
      ),
      callback,
    );
  };

  return (
    <Box width="100%" display="inline-flex" justifyContent="space-between">
      <Container
        title="Tarjetas"
        style={{ margin: 16 }}
        headerRightComponent={(
          <Button onClick={handleAddClick}>
            Agregar
          </Button>
        )}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ width: 80  }} />
                {config.columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {cards
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>
                        <IconButton color="error" aria-label="email" onClick={() => handleDeleteClick(row)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      {config.columns.map((column: any) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : column.type === 'month'
                                ? months[value - 1]
                                : column.type === 'last4'
                                  ? `XXXX-XXXX-XXXX-${value}`
                                  : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <Pagination
            count={cards.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <CardAddDialog
          open={showCardAddDialog}
          onClose={handleCardAddDialogClose}
          onAddCard={handleAddCard}
        />
      </Container>
    </Box>
  );
};

export default CardsTab;
