import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  containerLogin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "90vh",
    flexDirection: "column"
  },
  logo: {
    marginBottom: "20px",
    width: "300px"
  },
  login: {
    width: "90%",
    padding: "1px 30px 20px 30px",
    borderRadius: "10px",
    background: "whitesmoke",
    border: "1px solid #F9F9F9",
    textAlign: "center",
    "& h2": {
      fontWeight: "lighter",
    },
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
  },
  "@media (min-width: 500px)": {
    login: {
      width: "380px",
    },
  },
}));
