import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import * as _ from "lodash";
import {
  Button as MuiButton,
  Select as MuiSelect,
  Checkbox as MuiCheckbox,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
  IconButton as MuiIconButton,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material";
import {
  DesktopDatePicker as MuiDesktopDatePicker,
} from "@mui/x-date-pickers";
import {
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers/LocalizationProvider";
import {
  AdapterDayjs as MuiAdapterDayjs,
} from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";
import {
  countries, currencies, timezones, accountTypes, sponsorTypes,
} from "../constants";

const { REACT_APP_API_LOCATION } = process.env;

const config = {
  type: 'text',
  fullWidth: true,
  size: 'small',
  variant: 'outlined',
  InputLabelProps: {
    shrink: true,
  },
  inputProps: {
    style: { padding: '4px 8px' },
  },
};

export const TextField = (props: any) => (
  <MuiTextField
    {...config}
    {...props}
  />
);

export const NumberField = (props: any) => (
  <TextField
    {...props}
    type="number"
  />
);

export const TextArea = (props: any) => (
  <TextField
    {...props}
    InputProps={{
      style: { padding: '0' }
    }}
    multiline
  />
);

export const Filter = (props: any) => (
  <TextField
    {...props}
    placeholder={props.name ? `Filtrar por ${props.name.toLowerCase()}...` : 'Filtro...'}
    InputProps={{
      startAdornment: (
        <MuiIconButton disabled color="default" aria-label="email" style={{ padding: 4 }}>
          <SearchIcon fontSize="small" />
        </MuiIconButton>
      )
    }}
  />
);

export const Checkbox = (props: any) => (
  <MuiFormControlLabel
    {...props}
    labelPlacement="end"
    control={<MuiCheckbox />}
  />
);

export const DatePicker = (props: any) => (
  <MuiLocalizationProvider dateAdapter={MuiAdapterDayjs}>
    <MuiDesktopDatePicker
      {...props}
      onChange={(newValue) => props.onChange({ target: { name: props.name, value: newValue }})}
      inputFormat="MM/DD/YYYY"
      renderInput={(params: any) => <MuiTextField {...config} {...params} />}
    />
  </MuiLocalizationProvider>
);

export const MonthPicker = (props: any) => (
  <MuiLocalizationProvider dateAdapter={MuiAdapterDayjs}>
    <MuiDesktopDatePicker
      {...props}
      views={['year', 'month']}
      inputFormat="MM/YYYY"
      onChange={(newValue) => props.onChange({ target: { name: props.name, value: newValue }})}
      renderInput={(params: any) => <MuiTextField {...config} {...params} variant={props.variant || config.variant} />}
    />
  </MuiLocalizationProvider>
);

export const Select = (props: any) => (
  <TextField
    {...props}
    select
    inputProps={{
      sx: { padding: '4px 8px' },
      ...props.inputProps,
    }}
  >
    {props.children}
  </TextField>
);

export const Multiple = (props: any) => (
  <MuiSelect
    {...props}
    multiple
  >
    {props.children}
  </MuiSelect>
);

export const CountrySelect = (props: any) => (
  <Select {...props}>
    {countries.map((country: string) => (
      <MuiMenuItem key={country} value={country}>
        {country}
      </MuiMenuItem>
    ))}
  </Select>
);

export const CurrencySelect = (props: any) => (
  <Select {...props}>
    {currencies.map(({ value, label }: { value: string, label: string }) => (
      <MuiMenuItem key={value} value={value}>
        {label}
      </MuiMenuItem>
    ))}
  </Select>
);

export const TimezoneSelect = (props: any) => (
  <Select {...props}>
    {timezones.map(({ id, displayName }: { id: string, displayName: string }) => (
      <MuiMenuItem key={id} value={id}>
        {displayName}
      </MuiMenuItem>
    ))}
  </Select>
);

export const AccountTypeSelect = (props: any) => (
  <Select {...props}>
    {accountTypes.map(({ value, label }: { value: number, label: string }) => (
      <MuiMenuItem key={value} value={value}>
        {label}
      </MuiMenuItem>
    ))}
  </Select>
);

export const SponsorTypeSelect = (props: any) => (
  <Select {...props}>
    {sponsorTypes.map(({ value, label }: { value: string, label: string }) => (
      <MuiMenuItem key={value} value={value}>
        {label}
      </MuiMenuItem>
    ))}
  </Select>
);

export const SponsorsSelect = (props: any) => {
  const [sponsors, setSponsors] = useState<any[]>([]);

  const load = useCallback(_.debounce(() => {
    axios.get(`${REACT_APP_API_LOCATION}/Admin/SponsorList`).then(({ data: response }: any) => {
      setSponsors(response.data);
    });
  }, 500), [])

  useEffect(() => {
    load();
  }, []);

  return (
    <Multiple
      fullWidth
      inputProps={{
        sx: { padding: '8.5px 0 8.5px 14px' },
        ...props.inputProps,
      }}
      {...props}
    >
      {sponsors.map(({ id, name }: { id: string, name: string }) => (
        <MuiMenuItem key={id} value={id}>
          {name}
        </MuiMenuItem>
      ))}
    </Multiple>
  );
};

export const Button = (props: any) => (
  <MuiButton
    size="small"
    color="primary"
    variant="contained"
    {...props}
  />
);
