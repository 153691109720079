const columns = [
  { id: 'email', label: 'Correo', minWidth: 170 },
  { id: 'name', label: 'Nombre', minWidth: 170 },
  { id: 'membershipEndDate', label: 'Fecha de Vencimiento Membresía', minWidth: 170, type: 'date' },
  { id: 'ordersQty', label: 'Total Ordenes', minWidth: 170 },
];

const config = {
  columns,
};

export default config;
