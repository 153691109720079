import React, { useEffect, useState } from 'react';
import { Backdrop as MuiBackdrop, CircularProgress } from '@mui/material';
import service from './service';

export const Backdrop = ({ open }: {  open: boolean }) => (
  <MuiBackdrop open={open} style={{ zIndex: 9999 }}>
    <CircularProgress color="primary" />
  </MuiBackdrop>
);

const BlockUI = ({ name }: {  name: string }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const subscription = service.subscribe((state: {  name: string, open: boolean }) => {
      if (state.name !== name) return;
      setOpen(() => state.open);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [name]);

  return (
    <Backdrop open={open} />
  );
};

export default BlockUI;
