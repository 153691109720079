import React, { ReactElement, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "../../../config/controls";
import { useAppSelector } from "../../../hooks";
import service from "./service";
import api from "./api";

interface StateLike {
  open: boolean;
  title: string;
  message: ReactElement;
  callback: () => void;
}
const Confirm = () => {
  const { name } = useAppSelector((state) => state.user);
  const [password, setPassword] = useState<string>("");
  const [state, setState] = useState<StateLike>({
    open: false, title: "", message: <></>,callback: () => {},
  });

  const onConfirm = (event: any) => {
    event.stopPropagation();
    if (!password) return;
    api.login({ username: name, password }).then(() => {
      state.callback();
      onClose();
    });
  };

  const onClick = (event: any) => {
    event.stopPropagation();
  };

  const onClose = () => {
    setPassword("");
    setState((prev) => ({ ...prev, open: false }));
  };

  const onSubscribe = ({ title, message, callback }: { title: string, message: any, callback: () => void }) => {
    setPassword("");
    setState({
      open: true, title, message, callback,
    });
  };

  const handleChange = (event: any) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    const subscription = service.subscribe(onSubscribe);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Dialog
      fullWidth
      open={state.open}
      onClick={onClick}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography style={{ fontWeight: "bold" }}>
          {state.title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box marginBottom="16px">{state.message}</Box>
        <Typography style={{ fontSize: "12px", marginBottom: "16px" }}>
          Para poder eliminar es necesario que compruebes tu cuenta
        </Typography>
        <TextField
          label="Contraseña"
          name="password"
          value={password}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancelar
        </Button>
        <Button color="error" onClick={onConfirm} disabled={!password || password.trim() === ""}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
