import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Container from '../../../../app/components/theme/Container';
import { TextField, Button } from '../../../../app/config/controls';
import SubAccountsGrid from './SubAccountsGrid';
import SubAccountDialog from './SubAccountDialog';

interface Props {
  account: {
    id: string;
    fullname: string;
    email: string;
    password: string;
  };
  subAccounts: any[];
  onAddSubAccount: (subAccount: any) => void;
  onEditSubAccount: (subAccount: any) => void;
  onDeleteSubAccount: (subAccountId: string) => void;
  onChange: (event: any) => void;
}
const AccountTab = ({
  account,
  subAccounts,
  onAddSubAccount,
  onEditSubAccount,
  onDeleteSubAccount,
  onChange
}: Props) => {
  const [showSubAccountDialog, setShowSubAccountDialog] = useState<boolean>(false);

  const handleAddClick = () => {
    setShowSubAccountDialog(true);
  };

  const handleSubAccountDialogClose = () => {
    setShowSubAccountDialog(false);
  };

  return (
    <Box padding="16px">
      <Container title="Cuenta Dueño">
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12}>
            <TextField
              value={account.fullname}
              label="Nombre"
              name="fullname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={account.email}
              label="Correo"
              name="email"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={account.password}
              label="Contraseña"
              type="password"
              name="password"
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Container>
      <Container
        title="Subcuentas"
        headerRightComponent={(
          <Button onClick={handleAddClick}>
            Agregar
          </Button>
        )}
      >
        <Box padding="16px">
          <SubAccountsGrid
            accountId={account.id}
            subAccounts={subAccounts}
            onEditSubAccount={onEditSubAccount}
            onDeleteSubAccount={onDeleteSubAccount}
          />
        </Box>
      </Container>
      <SubAccountDialog
        accountId={account.id}
        open={showSubAccountDialog}
        onClose={handleSubAccountDialogClose}
        onAddSubAccount={onAddSubAccount}
      />
    </Box>
  )
};

export default AccountTab;
