import axios from 'axios';

const setHeaders = async (config: any) => {
  const updatedConfig = { ...config };
  const token = localStorage.getItem('samii-admin-session-token');
  updatedConfig.headers.Authorization = `Bearer ${token}`;
  return updatedConfig;
};

const configAxios = () => {
  axios.interceptors.request.use(setHeaders);
};

export default configAxios;
