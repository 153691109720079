import axios from "axios";
import moment from "moment";
import BlockUIService from "../../../app/components/theme/BlockUI/service";
import SnackbarService from "../../../app/components/theme/Snackbar/service";

const { REACT_APP_API_LOCATION } = process.env;

const COUPONS = `${REACT_APP_API_LOCATION}/Coupons`;
const ADMIN_ACCOUNT = `${REACT_APP_API_LOCATION}/Admin/Account`;
const ACCOUNT_ADD_COUPON = `${REACT_APP_API_LOCATION}/Account/AddCoupon`;
const PAYMENTS_CREATE_CARD = `${REACT_APP_API_LOCATION}/Payments/CreateCard`;
const PAYMENTS_DELETE_CARD = `${REACT_APP_API_LOCATION}/Payments/DeleteCard`;
const ADMIN_ADD_SUB_ACCOUNT = `${REACT_APP_API_LOCATION}/Admin/AddSubAccount`;
const ACCOUNT_DELETE_COUPON = `${REACT_APP_API_LOCATION}/Account/DeleteCoupon`;

const mapGetResponse = (account: any) => ({
  id: account.id,
  fullname: account.fullname || '',
  email: account.email || '',
  password: '',
  businessInfoName: account.businessInfo?.name || '',
  businessInfoCountry: account.businessInfo?.country || '',
  businessInfoPhone: account.businessInfo?.phone?.replaceAll(' ', '') || "''|''",
  businessInfoAddress: account.businessInfo?.address || '',
  businessInfoLogo: account.businessInfo?.logo || '',
  businessInfoCurrency: account.businessInfo?.currency || '',
  businessInfoPolicy: account.businessInfo?.policy || '',
  businessInfoTimezone: account.businessInfo?.timezone || '',
  businessInfoMembershipEndDate: account.businessInfo?.membershipEndDate || '',
  coupons: account.coupons.map((coupon: any) => ({
    id: coupon.id,
    code: coupon.code,
    isPercent: coupon.isPercent,
    amountOff: coupon.amountOff,
    percentOff: coupon.percentOff,
  })),
  cards: account.cards.map((card: any) => ({
    id: card.id,
    brand: card.brand,
    expMonth: card.expMonth,
    expYear: card.expYear,
    last4: card.last4,
    country: card.country,
  })),
  subAccounts: account.subAccounts.map((subAccount: any) => ({
    id: subAccount.id,
    fullname: subAccount.fullname,
    email: subAccount.email,
    accountType: subAccount.accountType,
  })),
});

const mapPutRequest = (patchFields: any) => Object.keys(patchFields).reduce((acc: any, curr: string) => {
  switch (curr) {
    case 'fullname':
    case 'email':
    case 'password':
      return { ...acc, [curr]: patchFields[curr] };
    case 'businessInfoName':
      return { ...acc, businessInfo: { ...acc.businessInfo, name: patchFields[curr] } };
    case 'businessInfoCountry':
      return { ...acc, businessInfo: { ...acc.businessInfo, country: patchFields[curr] } };
    case 'businessInfoPhone':
      return { ...acc, businessInfo: { ...acc.businessInfo, phone: patchFields[curr] } };
    case 'businessInfoAddress':
      return { ...acc, businessInfo: { ...acc.businessInfo, address: patchFields[curr] } };
    case 'businessInfoCurrency':
      return { ...acc, businessInfo: { ...acc.businessInfo, currency: patchFields[curr] } };
    case 'businessInfoPolicy':
      return { ...acc, businessInfo: { ...acc.businessInfo, policy: patchFields[curr] } };
    case 'businessInfoTimezone':
      return { ...acc, businessInfo: { ...acc.businessInfo, timezone: patchFields[curr] } };
    case 'businessInfoMembershipEndDate':
      return { ...acc, businessInfo: { ...acc.businessInfo, membershipEndDate: moment(new Date(patchFields[curr])).format('MM/DD/YYYY') } };
    default:
      return acc;
  }
}, {});

const load = async (id: string) => {
  BlockUIService.show();
  try {
    const response = await axios.get(`${ADMIN_ACCOUNT}/${id}`);
    return mapGetResponse(response.data);
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const update = async (id: string, patchFields: any) => {
  BlockUIService.show();
  try {
    const data: any = mapPutRequest(patchFields);
    await axios.patch(`${ADMIN_ACCOUNT}/${id}`, data);
    await SnackbarService.show('La cuenta se actualizó correctamente', 'success');
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const searchCoupon = async (coupon: string) => {
  BlockUIService.show();
  try {
    const response = await axios.get(`${COUPONS}?promotionCode=${coupon}`);
    return response.data;
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const addCoupon = async (accountId: string, couponId: string) => {
  BlockUIService.show();
  try {
    const data = { accountId, couponId };
    await axios.post(ACCOUNT_ADD_COUPON, data);
    await SnackbarService.show('El cupon se creó correctamente', 'success');
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const deleteCoupon = async (accountId: string, couponId: string) => {
  BlockUIService.show();
  try {
    const data = { accountId, couponId };
    await axios.delete(`${ACCOUNT_DELETE_COUPON}/${couponId}`, { data });
    await SnackbarService.show('El cupon se eliminó correctamente', 'success');
    return couponId;
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const addCard = async (accountId: string, token: string) => {
  BlockUIService.show();
  try {
    const data = { accountId, token };
    const response = await axios.post(PAYMENTS_CREATE_CARD, data);
    await SnackbarService.show('La tarjeta se creó correctamente', 'success');
    return response.data;
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const deleteCard = async (cardId: string) => {
  BlockUIService.show();
  try {
    await axios.delete(`${PAYMENTS_DELETE_CARD}/${cardId}`);
    await SnackbarService.show('La tarjeta se eliminó correctamente', 'success');
    return cardId;
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const addSubAccount = async (accountId: string, { email, fullname, accountType }: any) => {
  BlockUIService.show();
  try {
    const data = { email, fullname, accountType };
    const response = await axios.post(`${ADMIN_ADD_SUB_ACCOUNT}/${accountId}`, data);
    await SnackbarService.show('La subcuenta se creó correctamente', 'success');
    return { ...data, id: response.data.id };
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const editSubAccount = async (
  { id, email, fullname, accountType }: any
) => {
  BlockUIService.show();
  try {
    const data = { email, fullname, accountType };
    await axios.patch(`${ADMIN_ACCOUNT}/${id}`, data);
    await SnackbarService.show('La subcuenta se actualizó correctamente', 'success');
    return { ...data, id };
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const deleteSubAccount = async (subAccountId: string) => {
  BlockUIService.show();
  try {
    await axios.delete(`${ADMIN_ACCOUNT}/${subAccountId}`);
    await SnackbarService.show('La subcuenta se eliminó correctamente', 'success');
    return subAccountId;
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const api = {
  load,
  update,
  coupon: {
    search: searchCoupon,
    add: addCoupon,
    delete: deleteCoupon,
  },
  card: {
    add: addCard,
    delete: deleteCard,
  },
  subAccounts: {
    add: addSubAccount,
    edit: editSubAccount,
    delete: deleteSubAccount,
  },
};

export default api;
