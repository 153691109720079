import axios from "axios";
import BlockUIService from "../../../app/components/theme/BlockUI/service";
import SnackbarService from "../../../app/components/theme/Snackbar/service";

const { REACT_APP_API_LOCATION } = process.env;

const ADMIN_SPONSOR = `${REACT_APP_API_LOCATION}/Admin/Sponsor`;

const api = {
  load: async (sponsorId: string | null) => {
    BlockUIService.show();
    try {
      const response = await axios.get(`${ADMIN_SPONSOR}/${sponsorId}`);
      return response.data;
    } catch (e: any) {
      const message = e?.response?.data?.error?.message;
      await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
      throw e;
    } finally {
      BlockUIService.hide();
    }
  },
  update: async (sponsorId: string | null, data: any) => {
    BlockUIService.show();
    try {
      await axios.patch(`${ADMIN_SPONSOR}/${sponsorId}`, data);
      await SnackbarService.show('La cuenta se actualizó correctamente', 'success');
    } catch (e: any) {
      const message = e?.response?.data?.error?.message;
      await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
      throw e;
    } finally {
      BlockUIService.hide();
    }
  },
};

export default api;
