import axios from "axios";
import SnackbarService from "../../../app/components/theme/Snackbar/service";
import BlockUIService from "../../../app/components/theme/BlockUI/service";

const { REACT_APP_API_LOCATION } = process.env;

const ADMIN_SPONSOR_LIST = `${REACT_APP_API_LOCATION}/Admin/SponsorList`;
const ADMIN_ACCOUNT = `${REACT_APP_API_LOCATION}/Admin/Account`;

const mapGetResponse = ({ data }: any) => data.map((sponsor: any) => ({
  id: sponsor.id,
  name: sponsor.name,
  email: sponsor.email,
  coupons: sponsor.coupons.join(", "),
  accountId: sponsor.accountId,
}));

const api = {
  load: async (textSearch: string, pageNumber: number, pageSize: number) => {
    BlockUIService.show();
    try {
      const params = { pageNumber, pageSize } as { textSearch: string, pageNumber: number, pageSize: number };
      if (textSearch) params.textSearch = textSearch;
      const response = await axios.get(ADMIN_SPONSOR_LIST, { params });
      return {
        entities: mapGetResponse(response.data),
        pagination: response.data.metadata,
      }
    } catch (e: any) {
      const message = e?.respose?.data?.error?.message;
      await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
      return {
        entities: [],
        pagination: {
          total: 0,
          pages: 1,
          pageNumber: 1,
        },
      }
    } finally {
      BlockUIService.hide();
    }
  },
  delete: async (accountId: string) => {
    BlockUIService.show();
    try {
      await axios.delete(`${ADMIN_ACCOUNT}/${accountId}`);
      await SnackbarService.show('La cuenta se eliminó correctamente', 'success');
    } catch (e: any) {
      const message = e?.response?.data?.error?.message;
      await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
      throw e;
    } finally {
      BlockUIService.hide();
    }
  },
};

export default api;
