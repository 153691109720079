const columns = [
  { id: 'brand', label: 'Marca', minWidth: 170 },
  { id: 'expMonth', label: 'Mes Exp.', minWidth: 170, type: 'month' },
  { id: 'expYear', label: 'Año Exp.', minWidth: 170 },
  { id: 'last4', label: 'Tarjeta', minWidth: 170, type: 'last4' },
  { id: 'country', label: 'País', minWidth: 170 },
];

const config = {
  columns,
};

export default config;
