import React, { useEffect, useState } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";
import { useAppDispatch, useAppSelector } from "../../hooks";
import logo from "../../../assets/img/logo.png";
import { destroy } from "../../store/user";
import useStyles from "./styles";

const AppLayout = ({ children }: any) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { menus } = useAppSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const selected = (pathName: string) => location.pathname.includes(pathName);

  const handleAvatarClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    dispatch(destroy());
    history.push("/");
    localStorage.removeItem("samii-admin-session-token");
  };

  const onScreenResize = (event: any) => {
    if (event.target.innerWidth < 800) setCollapsed(true);
  };

  useEffect(() => {
    window.addEventListener("resize", onScreenResize);

    return () => {
      window.removeEventListener("resize", onScreenResize);
    };
  }, []);

  return (
    <Box>
      <nav className={classes.headerAppRoot}>
        <Box className={classes.headerAppContainer}>
          <Box className={classes.headerApp}>
            <Box className={classes.headerBurgerIcon}>
              <IconButton
                color="primary"
                onClick={() => setCollapsed((prev) => !prev)}
              >
                <MenuIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box className={classes.headerBrandLogo}>
              <img src={logo} alt="logo" width="40px" />
              <Typography color={"#8B5CF6"} style={{marginLeft:"10px", fontSize: "1rem", fontWeight: "bold" }}>
                Administrador
              </Typography>
            </Box>
            <Box className={classes.headerAvatarIcon}>
              <Tooltip title="Mi Cuenta">
                <IconButton
                  onClick={handleAvatarClick}
                  size="small"
                  color="primary"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleAvatarClose}
                onClick={handleAvatarClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleLogoutClick}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Cerrar Sesión
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </nav>
      <Box className={classes.bodyAppRoot}>
        <aside
          className={classes.menuAppRoot}
          style={{ width: collapsed ? "35px" : "200px" }}
        >
          <nav>
            <Box className={classes.menuAppContainer}>
              <ul className={classes.menuAppList}>
                {menus.map((menu) => (
                  <Link key={menu.name} to={menu.url} style={{ textDecoration: 'none' }}>
                    <li className={`${classes.menuAppItem} ${selected(menu.url) ? classes.menuAppItemSelected : ''}`}>
                      <Tooltip title={menu.name}>
                        <i className={`${classes.menuAppItemIcon} ${menu.icon}`} />
                      </Tooltip>
                      {collapsed ? "" : <span style={{ fontSize: "14px" }}>{menu.name}</span>}
                    </li>
                  </Link>
                ))}
              </ul>
            </Box>
          </nav>
        </aside>
        <main
          className={classes.contentAppRoot}
          style={{
            left: collapsed ? "50px" : "215px",
            width: collapsed ? "calc(100% - 80px)" : "calc(100% - 245px)",
          }}
        >
          {children}
        </main>
      </Box>
    </Box>
  );
};

export default AppLayout;
