import React from 'react';
import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TablePagination as MuiTablePagination,
} from '@mui/material';

export const Table = ({ children, ...props }: any) => (
  <MuiTableContainer {...props} sx={{ maxHeight: 500 }}>
    <MuiTable sx={{ minWidth: 650 }} size="small">
      {children}
    </MuiTable>
  </MuiTableContainer>
);

export const Pagination = ({ metadata, ...props }: any) => (
  <MuiTablePagination
    {...props}
    count={metadata?.total ?? props.count}
    rowsPerPageOptions={[10, 25, 50]}
    component="div"
    labelRowsPerPage={(<span>Registros por pagina</span>)}
    labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
      if (metadata) {
        return `${metadata.pageNumber} de ${metadata.pages}`;
      }
      return `${from}–${to} de ${count !== -1 ? count : `mas que ${to}`}`;
    }}
  />
);
