import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import moment from "moment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmService from "../../app/components/theme/Confirm/service";
import { Table, Pagination } from "../../app/config/tables";
import { Filter } from "../../app/config/controls";
import config from "./config";
import api from "./api";

const Accounts = () => {
  const history = useHistory();
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [textSearch, setTextSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [metadata, setMetadata] = useState<any>({
    total: 0,
    pages: 1,
    pageNumber: 1,
  });

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleTextSearchChange = (event: any) => {
    setTextSearch(event.target.value);
    setPage(0);
  };

  const handleEditClick = (rowId: string) => {
    history.push(`/account/${rowId}`);
  };

  const handleDeleteClick = (row: any) => {
    const callback = () => api.delete(row.id).then(() => {
      load(textSearch, page, rowsPerPage);
    });
    ConfirmService.show(
      "Eliminar",
      (
        <Box>
          <Typography style={{ fontWeight: "bold" }}>
            Estas seguro que deseas eliminar la cuenta "{row.name}"?
          </Typography>
          <Box>
            <Typography style={{ color: "red" }}>
              Advertencia: Esta eliminación es permatente y eliminará TODA la información de la cuenta DUEÑO, esto incluye: ORDENES, CLIENTES, INVENTARIO, DISPOSITIVOS, TARJETAS, PAGOS, USO DE CUPONES Y  SUSCRIPCIONES.
            </Typography>
          </Box>
        </Box>
      ),
      callback,
    );
  };

  const load = useCallback(
    _.debounce(
      (
        currTextSearch: string,
        currPageNumber: number,
        currPageSize: number
      ) => {
        api
          .load(currTextSearch, currPageNumber + 1, currPageSize)
          .then(({ entities, pagination }: any) => {
            setRows(entities);
            setMetadata(pagination);
          });
      },
      500
    ),
    []
  );

  useEffect(() => {
    load(textSearch, page, rowsPerPage);
  }, []);

  useEffect(() => {
    load(textSearch, page, rowsPerPage);
  }, [textSearch, page, rowsPerPage]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Grid container spacing={2} style={{ padding: "0 8px", alignItems: "center" }}>
        <Grid item xs={3} sm={5} md={6} lg={8} xl={8}>
          <Typography style={{ fontSize: "2rem", fontWeight: "bold" }}>
            Cuentas
          </Typography>
        </Grid>
        <Grid item xs={9} sm={7} md={6} lg={4} xl={4}>
          <Filter onChange={handleTextSearchChange} />
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 80 }} />
            {config.columns.map((column) => (
              <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <IconButton color="primary" onClick={() => handleEditClick(row.id)}>
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton color="error" onClick={() => handleDeleteClick(row)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
              {config.columns.map((column: any) => {
                const value = row[column.id];
                return (
                  <TableCell key={column.id} align={column.align}>
                    {column.format && typeof value === "number"
                      ? column.format(value)
                      : column.type === "date"
                      ? moment(value).format("MM/DD/YYYY")
                      : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        metadata={metadata}
      />
    </Paper>
  );
};

export default Accounts;
