import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Highcharts from "highcharts";
import Chart from "highcharts-react-official";
import SponsorPayments from "../../app/components/SponsorPayments";
import { Button, MonthPicker } from "../../app/config/controls";
import { useAppSelector } from "../../app/hooks";
import { Table } from "../../app/config/tables";
import History from "./components/History";
import Card from "./components/Card";
import api from "./api";

const SponsorCommissions = () => {
  const paymentsRef = useRef<any>({ current: { reload: () => {} } });
  const history = useHistory();
  const { sponsorId, name } = useAppSelector((state) => state.user);
  const [state, setState] = useState<any>({
    commissions: { total: 0, payments: [] },
    paidCoupons: { total: 0, payments: [] },
    registeredCoupons: { total: 0, clientsRegistered: [] },
  });
  const [filter, setFilter] = useState<any>(new Date());
  const [showRegisteredCoupons, setShowRegisteredCoupons] = useState<boolean>(false);
  const [showPayedCoupons, setShowPayedCoupons] = useState<boolean>(false);
  const [showCommissions, setShowCommissions] = useState<boolean>(false);

  const handleFilterChange = (event: any) => {
    setFilter(new Date(event.target.value));
  };

  useEffect(() => {
    const params = { sponsorId, date: moment(filter).format("YYYY-MM") };
    api.load(params).then(setState);
  }, [filter]);

  return (
    <Paper sx={{ padding: '8px' }}>
      <Grid container spacing={2} style={{ marginBottom: "8px", textAlign: "center" }}>
        <Grid item xs={12} padding="8px 0 !important">
          <Typography fontWeight="bold" fontSize="24px" color="grey">
            {moment(new Date()).format("DD/MM/YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={12} padding="0 !important">
          <Typography fontWeight="bold" fontSize="24px" color="grey">
            Saludos, {name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: "8px" }}>
        <Grid item xs={3}>
          <MonthPicker label="Filtrar por Mes" name="filter" value={filter} onChange={handleFilterChange} />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: "8px" }}>
        <Grid item xs={4}>
          <Card
            backgroundColor="#26A271"
            title="Cupones Registrados"
            description="Registrados en el periodo"
            value={state.registeredCoupons.total}
            onClick={() => setShowRegisteredCoupons(true)}
          />
        </Grid>
        <Grid item xs={4}>
          <Card
            backgroundColor="#8B5CF5"
            title="Cupones Pagados"
            description="Usados en el periodo"
            value={state.paidCoupons.total}
            onClick={() => setShowPayedCoupons(true)}
          />
        </Grid>
        <Grid item xs={4}>
          <Card
            backgroundColor="#FF6781"
            title="Comisiones"
            description="Del periodo"
            subtitle={`Ventas del Mes $ ${state.commissions.totalSales}`}
            value={`$ ${state.commissions.total}`}
            onClick={() => setShowCommissions(true)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Chart
            highcharts={Highcharts}
            options={{
              chart: {
                renderTo: "container",
                type: "pie",
              },
              title: {
                text: "Cupones",
              },
              plotOptions: {
                pie: {
                  shadow: false,
                },
              },
              series: [{
                name: "Cupones",
                data: [
                  { name: "Registrados", y: state.registeredCoupons.total, color: "#6DE7B7" },
                  { name: "Pagados", y: state.paidCoupons.total, color: "#A5B3FC" },
                ],
                size: "100%",
                innerSize: "25%",
                showInLegend: true,
                dataLabels: {
                  enabled: false,
                },
              }],
            }}
          />
        </Grid>
        <Grid item xs={7}>
          <SponsorPayments
            ref={paymentsRef}
            action={(
              <Button onClick={() => history.push("/my-payment-information")}>
                Ver mi Información de Pagos
              </Button>
            )}
          />
        </Grid>
      </Grid>
      <History
        title="Cupones Registrados"
        open={showRegisteredCoupons}
        onClose={() => setShowRegisteredCoupons(false)}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ minWidth: 180 }}>Fecha de Registro</TableCell>
                <TableCell style={{ minWidth: 180 }}>Cliente</TableCell>
                <TableCell style={{ minWidth: 180 }}>Cupon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.registeredCoupons.clientsRegistered.map((row: any, index: number) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    {moment(row.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {row.fullname}
                  </TableCell>
                  <TableCell>
                    {row.coupon}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </History>
      <History
        title="Cupones Pagados"
        open={showPayedCoupons}
        onClose={() => setShowPayedCoupons(false)}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ minWidth: 180 }}>Fecha de Pago</TableCell>
                <TableCell style={{ minWidth: 180 }}>Cliente</TableCell>
                <TableCell style={{ minWidth: 180 }}>Monto del Pago</TableCell>
                <TableCell style={{ minWidth: 180 }}>Cupon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.paidCoupons.payments.map((row: any, index: number) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    {moment(row.paymentDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {row.client.fullname}
                  </TableCell>
                  <TableCell align="right">
                    {`$ ${row.amount}`}
                  </TableCell>
                  <TableCell>
                    {row.coupon}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </History>
      <History
        title="Comisiones"
        open={showCommissions}
        onClose={() => setShowCommissions(false)}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ minWidth: 180 }}>Fecha de Pago</TableCell>
                <TableCell style={{ minWidth: 180 }}>Cliente</TableCell>
                <TableCell style={{ minWidth: 180 }}>Monto del Pago</TableCell>
                <TableCell style={{ minWidth: 180 }}>Cupon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.commissions.payments.map((row: any, index: number) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    {moment(row.paymentDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {row.client.fullname}
                  </TableCell>
                  <TableCell align="right">
                    {`$ ${row.amount}`}
                  </TableCell>
                  <TableCell>
                    {row.coupon}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </History>
    </Paper>
  );
};

export default SponsorCommissions;
