import { Subject } from 'rxjs';

const Service = () => {
  const subject = new Subject();

  const show = (title = "", message = (<></>), callback = () => {}) => {
    subject.next({ title, message, callback });
  };

  const subscribe = (process: any) => subject.subscribe(process);

  return {
    show,
    subscribe,
  };
};

export default Service();
