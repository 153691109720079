import axios from "axios";
import BlockUIService from "../../BlockUI/service";
import SnackbarService from "../../Snackbar/service";

const { REACT_APP_API_LOCATION } = process.env;

const LOGIN = `${REACT_APP_API_LOCATION}/Admin/Login`;

const api = {
  login: async (data: any) => {
    BlockUIService.show();
    try {
      const response = await axios.post(LOGIN, data);
      return response.data;
    } catch (e: any) {
      const message = e?.response?.data?.error?.message;
      await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
      throw e;
    } finally {
      BlockUIService.hide();
    }
  },
};

export default api;
