import axios from "axios";
import BlockUIService from "../../../../../app/components/theme/BlockUI/service";
import SnackbarService from "../../../../../app/components/theme/Snackbar/service";

const { REACT_APP_API_LOCATION } = process.env;

const FILES  = `${REACT_APP_API_LOCATION}/Files`;
const CREATE_PAYMENT_REQUEST = `${REACT_APP_API_LOCATION}/Admin/CreatePaymentRequest`;

const createFile = async (file: any) => {
  const data = new FormData();
  data.append("File", file);
  data.append("Type", "3");
  const response = await axios.post(FILES, data);
  return response.data;
};

const api = {
  createPaymentRequest: async (accountId: string | null, data: any) => {
    BlockUIService.show();
    try {
      const invoicePdf = await createFile(data.files.invoicePdf);
      let invoiceXml = undefined;
      if (data.files.invoiceXml) invoiceXml = await createFile(data.files.invoiceXml);
      const firstEvidence = await createFile(data.files.firstEvidence);
      const secondEvidence = await createFile(data.files.secondEvidence);
      const response = await axios.post(CREATE_PAYMENT_REQUEST, {
        accountId,
        dateToPay: data.dateToPay,
        invoicePdf,
        invoiceXml,
        firstEvidence,
        secondEvidence,
      });
      await SnackbarService.show('La Solicitud de Pago se creó correctamente', 'success');
      return response.data;
    } catch (e: any) {
      const message = e?.response?.data?.error?.message;
      await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
      throw e;
    } finally {
      BlockUIService.hide();
    }
  },
};

export default api;
