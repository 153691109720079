import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface State {
  id: string | null;
  name: string;
  rol: string;
  menus: Array<any>;
  sponsorId: string | null;
}

const initialState: State = {
  id: null,
  name: "",
  rol: "",
  menus: [],
  sponsorId: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    store: (state, action: PayloadAction<any>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.rol = action.payload.rol;
      state.menus = action.payload.menus;
      state.sponsorId = action.payload.sponsorId;
    },
    destroy: (state) => {
      state.id = null;
      state.name = "";
      state.rol = "";
      state.menus = [];
      state.sponsorId = null;
    },
  },
});

export const { store, destroy } = authSlice.actions;

export default authSlice.reducer;
