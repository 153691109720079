import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  root: {
    padding: "8px",
    borderRadius: "4px",
    display: "inline-flex",
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    cursor: "pointer",
  },
}));
