import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AccountLike, CardLike, CouponLike } from "../../app/interfaces";
import BusinessInfoTab from "./components/BusinessInfoTab";
import Container from "../../app/components/theme/Container";
import TabPanel from "../../app/components/theme/TabPanel";
import { Button } from "../../app/config/controls";
import AccountTab from "./components/AccountTab";
import CouponsTab from "./components/CouponsTab";
import CardsTab from "./components/CardsTab";
import api from "./api";

const initialState: AccountLike = {
  id: '',
  fullname: '',
  email: '',
  password: '',
  businessInfoName: '',
  businessInfoCountry: '',
  businessInfoPhone: '',
  businessInfoAddress: '',
  businessInfoLogo: '',
  businessInfoCurrency: '',
  businessInfoPolicy: '',
  businessInfoTimezone: '',
  businessInfoMembershipEndDate: '',
  coupons: [],
  cards: [],
  subAccounts: [],
};

const Account = () => {
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [account, setAccount] = useState<AccountLike>(initialState);
  const [changes, setChanges] = useState<string[]>([]);

  const handleTabChange = (event: any, newValue: number) => setTabIndex(newValue);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event: any) => {
    setChanges((prev: string[]) => {
      if (!prev.includes(event.target.name)) return [...prev, event.target.name];
      return prev;
    });
    setAccount((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.type === 'checkbox'? event.target.checked : event.target.value,
    }));
  };

  const handleSave = () => {
    const patchFields = {};
    for (let i = 0; i < changes.length; i++) {
      const field: string = changes[i];
      // @ts-ignore
      patchFields[field] = account[field];
    }
    api.update(id, patchFields).then(() => {
      setChanges([]);
    });
  };

  const handleAddCoupon = (coupon: CouponLike) => {
    setAccount((prev: AccountLike) => ({
      ...prev,
      coupons: [...prev.coupons, coupon],
    }));
  };

  const handleDeleteCoupon = (id: string) => {
    setAccount((prev: AccountLike) => ({
      ...prev,
      coupons: prev.coupons.filter((c) => c.id !== id),
    }));
  };

  const handleAddCard = (card: CardLike) => {
    setAccount((prev: AccountLike) => ({
      ...prev,
      cards: [...prev.cards, card],
    }));
  };

  const handleDeleteCard = (id: string) => {
    setAccount((prev: AccountLike) => ({
      ...prev,
      cards: prev.cards.filter((c) => c.id !== id),
    }));
  };

  const handleAddSubAccount = (subAccount: any) => {
    setAccount((prev: AccountLike) => ({
      ...prev,
      subAccounts: [...prev.subAccounts, subAccount],
    }));
  };

  const handleEditSubAccount = (subAccount: any) => {
    setAccount((prev: AccountLike) => ({
      ...prev,
      subAccounts: prev.subAccounts.map((sa) => sa.id === subAccount.id ? subAccount : sa),
    }));
  };

  const handleDeleteSubAccount = (subAccountId: string) => {
    setAccount((prev: AccountLike) => ({
      ...prev,
      subAccounts: prev.subAccounts.filter((sa) => sa.id !== subAccountId),
    }));
  };

  useEffect(() => {
    api.load(id).then(setAccount);
  }, []);

  return (
    <Container
      title={account.fullname || 'Cuenta'}
      headerLeftComponent={(
        <ChevronLeftIcon onClick={handleBack} />
      )}
      headerRightComponent={(tabIndex === 0 || tabIndex === 1) && (
        <Button onClick={handleSave}>
          Guardar
        </Button>
      )}
    >
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          variant="scrollable"
        >
          <Tab tabIndex={0} label="Cuentas" />
          <Tab tabIndex={1} label="Información de Negocio" />
          <Tab tabIndex={2} label="Cupones" />
          <Tab tabIndex={3} label="Tarjetas" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <AccountTab
          account={{
            id: account.id,
            fullname: account.fullname,
            email: account.email,
            password: account.password,
          }}
          subAccounts={account.subAccounts}
          onAddSubAccount={handleAddSubAccount}
          onEditSubAccount={handleEditSubAccount}
          onDeleteSubAccount={handleDeleteSubAccount}
          onChange={handleChange}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <BusinessInfoTab
          businessInfo={{
            name: account.businessInfoName,
            country: account.businessInfoCountry,
            phone: account.businessInfoPhone,
            address: account.businessInfoAddress,
            logo: account.businessInfoLogo,
            currency: account.businessInfoCurrency,
            policy: account.businessInfoPolicy,
            timezone: account.businessInfoTimezone,
            membershipEndDate: account.businessInfoMembershipEndDate,
          }}
          onChange={handleChange}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <CouponsTab
          accountId={account.id}
          coupons={account.coupons}
          onAddCoupon={handleAddCoupon}
          onDeleteCoupon={handleDeleteCoupon}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <CardsTab
          accountId={account.id}
          cards={account.cards}
          onAddCard={handleAddCard}
          onDeleteCard={handleDeleteCard}
        />
      </TabPanel>
    </Container>
  );
};

export default Account;
