import axios from "axios";
import BlockUIService from "../../../app/components/theme/BlockUI/service";
import SnackbarService from "../../../app/components/theme/Snackbar/service";

const { REACT_APP_API_LOCATION } = process.env;

const COUPONS = `${REACT_APP_API_LOCATION}/Coupons`;
const ADMIN_SPONSOR = `${REACT_APP_API_LOCATION}/Admin/Sponsor`;
const ADMIN_CREATE_SPONSOR = `${REACT_APP_API_LOCATION}/Admin/CreateSponsor`;
const ACCOUNT_ADD_COUPON_SPONSOR = `${REACT_APP_API_LOCATION}/Admin/AddCouponSponsor`;
const ACCOUNT_REMOVE_COUPON_SPONSOR = `${REACT_APP_API_LOCATION}/Admin/RemoveCouponSponsor`;

const mapGetResponse = (sponsor: any) => ({
  id: sponsor.id,
  fullname: sponsor.fullname || '',
  email: sponsor.email || '',
  password: '',
  country: sponsor.country || '',
  currency: sponsor.currency || '',
  bankName: sponsor.bankName || '',
  bankAccount: sponsor.bankAccount || '',
  paypalAccount: sponsor.paypalAccount || '',
  type: sponsor.type || '',
  coupons: sponsor.coupons?.map((coupon: any) => ({
    id: coupon.id,
    code: coupon.code,
    isPercent: coupon.isPercent,
    amountOff: coupon.amountOff,
    percentOff: coupon.percentOff,
  })) || [],
  percent: sponsor.percent,
});

const mapPutRequest = (patchFields: any) => Object.keys(patchFields).reduce((acc: any, curr: string) => {
  switch (curr) {
    case 'fullname':
    case 'email':
    case 'password':
    case 'country':
    case 'currency':
    case 'bankName':
    case 'bankAccount':
    case 'paypalAccount':
    case 'type':
    case 'percent':
      return { ...acc, [curr]: patchFields[curr] };
    default:
      return acc;
  }
}, {});

const load = async (id: string) => {
  BlockUIService.show();
  try {
    const response = await axios.get(`${ADMIN_SPONSOR}/${id}`);
    return mapGetResponse(response.data);
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const create = async (sponsor: any) => {
  BlockUIService.show();
  try {
    const data: any = mapPutRequest(sponsor);
    const response = await axios.post(ADMIN_CREATE_SPONSOR, data);
    await SnackbarService.show('La cuenta se creó correctamente', 'success');
    return response.data;
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const update = async (id: string, patchFields: any) => {
  BlockUIService.show();
  try {
    const data: any = mapPutRequest(patchFields);
    await axios.patch(`${ADMIN_SPONSOR}/${id}`, data);
    await SnackbarService.show('La cuenta se actualizó correctamente', 'success');
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const searchCoupon = async (coupon: string) => {
  BlockUIService.show();
  try {
    const response = await axios.get(`${COUPONS}?promotionCode=${coupon}`);
    return response.data;
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const addCoupon = async (sponsorId: string, couponId: string) => {
  BlockUIService.show();
  try {
    const data = { sponsorId, couponId };
    await axios.post(ACCOUNT_ADD_COUPON_SPONSOR, data);
    await SnackbarService.show('El cupon se creó correctamente', 'success');
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const deleteCoupon = async (sponsorId: string, couponId: string) => {
  BlockUIService.show();
  try {
    const data = { sponsorId, couponId };
    await axios.delete(`${ACCOUNT_REMOVE_COUPON_SPONSOR}/${couponId}`, { data });
    await SnackbarService.show('El cupon se eliminó correctamente', 'success');
    return couponId;
  } catch (e: any) {
    const message = e?.response?.data?.error?.message;
    await SnackbarService.show(message || 'Ha ocurrido un problema', 'error');
    throw e;
  } finally {
    BlockUIService.hide();
  }
};

const api = {
  load,
  create,
  update,
  coupon: {
    search: searchCoupon,
    add: addCoupon,
    delete: deleteCoupon,
  },
};

export default api;
