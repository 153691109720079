const columns = [
  { id: "fullname", label: "Nombre", minWidth: 170 },
  { id: "email", label: "Correo", minWidth: 170 },
  { id: "accountType", label: "Tipo de Cuenta", minWidth: 170, type: "accountType" },
];

const config = {
  columns,
};

export default config;
