import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, TextField } from '../../../../../app/config/controls';

interface Props {
  open: boolean,
  onClose: any,
  onSearchCoupon: any,
}
const CouponSearchDialog = ({ open, onClose, onSearchCoupon }: Props) => {
  const [value, setValue] = useState<string>('');

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const handleCloseClick = () => {
    onClose();
    setValue('');
  };

  const handleSearchClick = () => {
    onSearchCoupon(value);
    handleCloseClick();
  };

  return (
    <Dialog open={open} onClose={handleCloseClick}>
      <DialogTitle>Agregar Cupon</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para poder agregar un cupon, es necesario validar que
          el cupon exista.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Cupon"
          value={value}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="info" onClick={handleCloseClick}>Cancelar</Button>
        <Button onClick={handleSearchClick} disabled={!value || value.trim() === ''}>Buscar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CouponSearchDialog;
