export const countries = [
  'Antigua y Barbuda',
  'Argentina',
  'Bahamas',
  'Barbados',
  'Belice',
  'Bolivia',
  'Brasil',
  'Canadá',
  'Chile',
  'Colombia',
  'Costa Rica',
  'Cuba',
  'Dominica',
  'Ecuador',
  'El Salvador',
  'Estados Unidos',
  'Granada',
  'Guatemala',
  'Guyana',
  'Haití',
  'Honduras',
  'Jamaica',
  'México',
  'Nicaragua',
  'Panamá',
  'Paraguay',
  'Perú',
  'República Dominicana',
  'San Cristóbal y Nieves',
  'San Vicente y las Granadinas',
  'Santa Lucía',
  'Surinam',
  'Trinidad y Tobago',
  'Uruguay',
  'Venezuela',
  'España',
  'Otro',
];

export const currencies = [
  { value: 'USD', label: 'USA' },
  { value: 'MXN', label: 'MÉXICO' },
  { value: 'EUR', label: 'EURO' },
  { value: 'ARS', label: 'ARGENTINA' },
  { value: 'COP', label: 'COLOMBIA' },
  { value: 'ECS', label: 'ECUADOR' },
  { value: 'GTQ', label: 'GUATEMALA' },
  { value: 'BS', label: 'VENEZUELA' },
  { value: 'PEN', label: 'PERU' },
  { value: 'CLP', label: 'CHILE' },
  { value: 'DOP', label: 'REPUBLICA DOMINICANA' },
  { value: 'HNL', label: 'HONDURAS' },
  { value: 'UYU', label: 'URUGUAY' },
  { value: 'NIO', label: 'NICARAGUA' },
  { value: 'PYG', label: 'PARAGUAY' },
  { value: 'BOB', label: 'BOLIVIA' },
  { value: 'SVC', label: 'EL SALVADOR' },
  { value: 'CUP', label: 'CUBA' },
  { value: 'CRC', label: 'COSTA RICA' },
  { value: 'BRL', label: 'BRASIL' },
  { value: 'PAB', label: 'PANAMA' },
  { value: 'BZD', label: 'BELICE' },
  { value: 'CAD', label: 'CANADA' },
  { value: 'CHF', label: 'SUIZA' },
];

export const timezones = [
  {
    id: "Pacific/Niue",
    displayName: "(UTC-11:00) Niue Time",
    standardName: "Niue Time",
    daylightName: "GMT-11:00"
  },
  {
    id: "Pacific/Midway",
    displayName: "(UTC-11:00) Samoa Standard Time (Midway)",
    standardName: "Samoa Standard Time",
    daylightName: "Samoa Daylight Time"
  },
  {
    id: "Pacific/Pago_Pago",
    displayName: "(UTC-11:00) Samoa Standard Time (Pago Pago)",
    standardName: "Samoa Standard Time",
    daylightName: "Samoa Daylight Time"
  },
  {
    id: "Pacific/Rarotonga",
    displayName: "(UTC-10:00) Cook Islands Standard Time (Rarotonga)",
    standardName: "Cook Islands Standard Time",
    daylightName: "Cook Islands Half Summer Time"
  },
  {
    id: "America/Adak",
    displayName: "(UTC-10:00) Hawaii-Aleutian Time (Adak)",
    standardName: "Hawaii-Aleutian Standard Time",
    daylightName: "Hawaii-Aleutian Daylight Time"
  },
  {
    id: "Pacific/Honolulu",
    displayName: "(UTC-10:00) Hawaii-Aleutian Time (Honolulu)",
    standardName: "Hawaii-Aleutian Standard Time",
    daylightName: "Hawaii-Aleutian Daylight Time"
  },
  {
    id: "Pacific/Tahiti",
    displayName: "(UTC-10:00) Tahiti Time",
    standardName: "Tahiti Time",
    daylightName: "GMT-10:00"
  },
  {
    id: "Pacific/Marquesas",
    displayName: "(UTC-09:30) Marquesas Time",
    standardName: "Marquesas Time",
    daylightName: "GMT-09:30"
  },
  {
    id: "America/Anchorage",
    displayName: "(UTC-09:00) Alaska Time (Anchorage)",
    standardName: "Alaska Standard Time",
    daylightName: "Alaska Daylight Time"
  },
  {
    id: "America/Juneau",
    displayName: "(UTC-09:00) Alaska Time (Juneau)",
    standardName: "Alaska Standard Time",
    daylightName: "Alaska Daylight Time"
  },
  {
    id: "America/Metlakatla",
    displayName: "(UTC-09:00) Alaska Time (Metlakatla)",
    standardName: "Alaska Standard Time",
    daylightName: "Alaska Daylight Time"
  },
  {
    id: "America/Nome",
    displayName: "(UTC-09:00) Alaska Time (Nome)",
    standardName: "Alaska Standard Time",
    daylightName: "Alaska Daylight Time"
  },
  {
    id: "America/Sitka",
    displayName: "(UTC-09:00) Alaska Time (Sitka)",
    standardName: "Alaska Standard Time",
    daylightName: "Alaska Daylight Time"
  },
  {
    id: "America/Yakutat",
    displayName: "(UTC-09:00) Alaska Time (Yakutat)",
    standardName: "Alaska Standard Time",
    daylightName: "Alaska Daylight Time"
  },
  {
    id: "Pacific/Gambier",
    displayName: "(UTC-09:00) Gambier Time",
    standardName: "Gambier Time",
    daylightName: "GMT-09:00"
  },
  {
    id: "America/Los_Angeles",
    displayName: "(UTC-08:00) Pacific Time (Los Angeles)",
    standardName: "Pacific Standard Time",
    daylightName: "Pacific Daylight Time"
  },
  {
    id: "America/Tijuana",
    displayName: "(UTC-08:00) Pacific Time (Tijuana)",
    standardName: "Pacific Standard Time",
    daylightName: "Pacific Daylight Time"
  },
  {
    id: "America/Vancouver",
    displayName: "(UTC-08:00) Pacific Time (Vancouver)",
    standardName: "Pacific Standard Time",
    daylightName: "Pacific Daylight Time"
  },
  {
    id: "Pacific/Pitcairn",
    displayName: "(UTC-08:00) Pitcairn Islands Time",
    standardName: "Pitcairn Time",
    daylightName: "GMT-08:00"
  },
  {
    id: "America/Hermosillo",
    displayName: "(UTC-07:00) Mexican Pacific Time (Hermosillo)",
    standardName: "Mexican Pacific Standard Time",
    daylightName: "Mexican Pacific Daylight Time"
  },
  {
    id: "America/Mazatlan",
    displayName: "(UTC-07:00) Mexican Pacific Time (Mazatlan)",
    standardName: "Mexican Pacific Standard Time",
    daylightName: "Mexican Pacific Daylight Time"
  },
  {
    id: "America/Boise",
    displayName: "(UTC-07:00) Mountain Time (Boise)",
    standardName: "Mountain Standard Time",
    daylightName: "Mountain Daylight Time"
  },
  {
    id: "America/Cambridge_Bay",
    displayName: "(UTC-07:00) Mountain Time (Cambridge Bay)",
    standardName: "Mountain Standard Time",
    daylightName: "Mountain Daylight Time"
  },
  {
    id: "America/Creston",
    displayName: "(UTC-07:00) Mountain Time (Creston)",
    standardName: "Mountain Standard Time",
    daylightName: "Mountain Daylight Time"
  },
  {
    id: "America/Dawson_Creek",
    displayName: "(UTC-07:00) Mountain Time (Dawson Creek)",
    standardName: "Mountain Standard Time",
    daylightName: "Mountain Daylight Time"
  },
  {
    id: "America/Denver",
    displayName: "(UTC-07:00) Mountain Time (Denver)",
    standardName: "Mountain Standard Time",
    daylightName: "Mountain Daylight Time"
  },
  {
    id: "America/Edmonton",
    displayName: "(UTC-07:00) Mountain Time (Edmonton)",
    standardName: "Mountain Standard Time",
    daylightName: "Mountain Daylight Time"
  },
  {
    id: "America/Fort_Nelson",
    displayName: "(UTC-07:00) Mountain Time (Fort Nelson)",
    standardName: "Mountain Standard Time",
    daylightName: "Mountain Daylight Time"
  },
  {
    id: "America/Inuvik",
    displayName: "(UTC-07:00) Mountain Time (Inuvik)",
    standardName: "Mountain Standard Time",
    daylightName: "Mountain Daylight Time"
  },
  {
    id: "America/Phoenix",
    displayName: "(UTC-07:00) Mountain Time (Phoenix)",
    standardName: "Mountain Standard Time",
    daylightName: "Mountain Daylight Time"
  },
  {
    id: "America/Yellowknife",
    displayName: "(UTC-07:00) Mountain Time (Yellowknife)",
    standardName: "Mountain Standard Time",
    daylightName: "Mountain Daylight Time"
  },
  {
    id: "America/Dawson",
    displayName: "(UTC-07:00) Pacific Time (Dawson)",
    standardName: "Pacific Standard Time",
    daylightName: "Pacific Daylight Time"
  },
  {
    id: "America/Whitehorse",
    displayName: "(UTC-07:00) Pacific Time (Whitehorse)",
    standardName: "Pacific Standard Time",
    daylightName: "Pacific Daylight Time"
  },
  {
    id: "America/Bahia_Banderas",
    displayName: "(UTC-06:00) Central Time (Bahia Banderas)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Belize",
    displayName: "(UTC-06:00) Central Time (Belize)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/North_Dakota/Beulah",
    displayName: "(UTC-06:00) Central Time (Beulah, North Dakota)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/North_Dakota/Center",
    displayName: "(UTC-06:00) Central Time (Center, North Dakota)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Chicago",
    displayName: "(UTC-06:00) Central Time (Chicago)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Costa_Rica",
    displayName: "(UTC-06:00) Central Time (Costa Rica)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/El_Salvador",
    displayName: "(UTC-06:00) Central Time (El Salvador)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Guatemala",
    displayName: "(UTC-06:00) Central Time (Guatemala)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Indiana/Knox",
    displayName: "(UTC-06:00) Central Time (Knox, Indiana)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Managua",
    displayName: "(UTC-06:00) Central Time (Managua)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Matamoros",
    displayName: "(UTC-06:00) Central Time (Matamoros)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Menominee",
    displayName: "(UTC-06:00) Central Time (Menominee)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Merida",
    displayName: "(UTC-06:00) Central Time (Merida)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Mexico_City",
    displayName: "(UTC-06:00) Central Time (Mexico City)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Monterrey",
    displayName: "(UTC-06:00) Central Time (Monterrey)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/North_Dakota/New_Salem",
    displayName: "(UTC-06:00) Central Time (New Salem, North Dakota)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Rainy_River",
    displayName: "(UTC-06:00) Central Time (Rainy River)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Rankin_Inlet",
    displayName: "(UTC-06:00) Central Time (Rankin Inlet)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Regina",
    displayName: "(UTC-06:00) Central Time (Regina)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Resolute",
    displayName: "(UTC-06:00) Central Time (Resolute)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Swift_Current",
    displayName: "(UTC-06:00) Central Time (Swift Current)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Tegucigalpa",
    displayName: "(UTC-06:00) Central Time (Tegucigalpa)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Indiana/Tell_City",
    displayName: "(UTC-06:00) Central Time (Tell City, Indiana)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "America/Winnipeg",
    displayName: "(UTC-06:00) Central Time (Winnipeg)",
    standardName: "Central Standard Time",
    daylightName: "Central Daylight Time"
  },
  {
    id: "Pacific/Easter",
    displayName: "(UTC-06:00) Easter Island Time",
    standardName: "Easter Island Standard Time",
    daylightName: "Easter Island Summer Time"
  },
  {
    id: "Pacific/Galapagos",
    displayName: "(UTC-06:00) Galapagos Time",
    standardName: "Galapagos Time",
    daylightName: "GMT-06:00"
  },
  {
    id: "America/Chihuahua",
    displayName: "(UTC-06:00) Mexican Pacific Time (Chihuahua)",
    standardName: "Mexican Pacific Standard Time",
    daylightName: "Mexican Pacific Daylight Time"
  },
  {
    id: "America/Ojinaga",
    displayName: "(UTC-06:00) Mountain Time (Ojinaga)",
    standardName: "Mountain Standard Time",
    daylightName: "Mountain Daylight Time"
  },
  {
    id: "America/Eirunepe",
    displayName: "(UTC-05:00) Acre Standard Time (Eirunepe)",
    standardName: "Acre Standard Time",
    daylightName: "Acre Summer Time"
  },
  {
    id: "America/Rio_Branco",
    displayName: "(UTC-05:00) Acre Standard Time (Rio Branco)",
    standardName: "Acre Standard Time",
    daylightName: "Acre Summer Time"
  },
  {
    id: "America/Bogota",
    displayName: "(UTC-05:00) Colombia Standard Time (Bogota)",
    standardName: "Colombia Standard Time",
    daylightName: "Colombia Summer Time"
  },
  {
    id: "America/Havana",
    displayName: "(UTC-05:00) Cuba Time",
    standardName: "Cuba Standard Time",
    daylightName: "Cuba Daylight Time"
  },
  {
    id: "America/Atikokan",
    displayName: "(UTC-05:00) Eastern Time (Atikokan)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Cancun",
    displayName: "(UTC-05:00) Eastern Time (Cancun)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Cayman",
    displayName: "(UTC-05:00) Eastern Time (Cayman)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Detroit",
    displayName: "(UTC-05:00) Eastern Time (Detroit)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Grand_Turk",
    displayName: "(UTC-05:00) Eastern Time (Grand Turk)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Indiana/Indianapolis",
    displayName: "(UTC-05:00) Eastern Time (Indianapolis)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Iqaluit",
    displayName: "(UTC-05:00) Eastern Time (Iqaluit)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Jamaica",
    displayName: "(UTC-05:00) Eastern Time (Jamaica)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Kentucky/Louisville",
    displayName: "(UTC-05:00) Eastern Time (Louisville)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Indiana/Marengo",
    displayName: "(UTC-05:00) Eastern Time (Marengo, Indiana)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Kentucky/Monticello",
    displayName: "(UTC-05:00) Eastern Time (Monticello, Kentucky)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Nassau",
    displayName: "(UTC-05:00) Eastern Time (Nassau)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/New_York",
    displayName: "(UTC-05:00) Eastern Time (New York)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Nipigon",
    displayName: "(UTC-05:00) Eastern Time (Nipigon)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Panama",
    displayName: "(UTC-05:00) Eastern Time (Panama)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Pangnirtung",
    displayName: "(UTC-05:00) Eastern Time (Pangnirtung)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Indiana/Petersburg",
    displayName: "(UTC-05:00) Eastern Time (Petersburg, Indiana)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Port-au-Prince",
    displayName: "(UTC-05:00) Eastern Time (Port-au-Prince)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Thunder_Bay",
    displayName: "(UTC-05:00) Eastern Time (Thunder Bay)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Toronto",
    displayName: "(UTC-05:00) Eastern Time (Toronto)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Indiana/Vevay",
    displayName: "(UTC-05:00) Eastern Time (Vevay, Indiana)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Indiana/Vincennes",
    displayName: "(UTC-05:00) Eastern Time (Vincennes, Indiana)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Indiana/Winamac",
    displayName: "(UTC-05:00) Eastern Time (Winamac, Indiana)",
    standardName: "Eastern Standard Time",
    daylightName: "Eastern Daylight Time"
  },
  {
    id: "America/Guayaquil",
    displayName: "(UTC-05:00) Ecuador Time",
    standardName: "Ecuador Time",
    daylightName: "GMT-05:00"
  },
  {
    id: "America/Lima",
    displayName: "(UTC-05:00) Peru Standard Time (Lima)",
    standardName: "Peru Standard Time",
    daylightName: "Peru Summer Time"
  },
  {
    id: "America/Boa_Vista",
    displayName: "(UTC-04:00) Amazon Standard Time (Boa Vista)",
    standardName: "Amazon Standard Time",
    daylightName: "Amazon Summer Time"
  },
  {
    id: "America/Campo_Grande",
    displayName: "(UTC-04:00) Amazon Standard Time (Campo Grande)",
    standardName: "Amazon Standard Time",
    daylightName: "Amazon Summer Time"
  },
  {
    id: "America/Cuiaba",
    displayName: "(UTC-04:00) Amazon Standard Time (Cuiaba)",
    standardName: "Amazon Standard Time",
    daylightName: "Amazon Summer Time"
  },
  {
    id: "America/Manaus",
    displayName: "(UTC-04:00) Amazon Standard Time (Manaus)",
    standardName: "Amazon Standard Time",
    daylightName: "Amazon Summer Time"
  },
  {
    id: "America/Porto_Velho",
    displayName: "(UTC-04:00) Amazon Standard Time (Porto Velho)",
    standardName: "Amazon Standard Time",
    daylightName: "Amazon Summer Time"
  },
  {
    id: "America/Anguilla",
    displayName: "(UTC-04:00) Atlantic Time (Anguilla)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Antigua",
    displayName: "(UTC-04:00) Atlantic Time (Antigua)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Aruba",
    displayName: "(UTC-04:00) Atlantic Time (Aruba)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Barbados",
    displayName: "(UTC-04:00) Atlantic Time (Barbados)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "Atlantic/Bermuda",
    displayName: "(UTC-04:00) Atlantic Time (Bermuda)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Blanc-Sablon",
    displayName: "(UTC-04:00) Atlantic Time (Blanc-Sablon)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Curacao",
    displayName: "(UTC-04:00) Atlantic Time (Curaçao)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Dominica",
    displayName: "(UTC-04:00) Atlantic Time (Dominica)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Glace_Bay",
    displayName: "(UTC-04:00) Atlantic Time (Glace Bay)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Goose_Bay",
    displayName: "(UTC-04:00) Atlantic Time (Goose Bay)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Grenada",
    displayName: "(UTC-04:00) Atlantic Time (Grenada)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Guadeloupe",
    displayName: "(UTC-04:00) Atlantic Time (Guadeloupe)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Halifax",
    displayName: "(UTC-04:00) Atlantic Time (Halifax)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Kralendijk",
    displayName: "(UTC-04:00) Atlantic Time (Kralendijk)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Lower_Princes",
    displayName: "(UTC-04:00) Atlantic Time (Lower Prince’s Quarter)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Marigot",
    displayName: "(UTC-04:00) Atlantic Time (Marigot)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Martinique",
    displayName: "(UTC-04:00) Atlantic Time (Martinique)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Moncton",
    displayName: "(UTC-04:00) Atlantic Time (Moncton)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Montserrat",
    displayName: "(UTC-04:00) Atlantic Time (Montserrat)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Port_of_Spain",
    displayName: "(UTC-04:00) Atlantic Time (Port of Spain)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Puerto_Rico",
    displayName: "(UTC-04:00) Atlantic Time (Puerto Rico)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Santo_Domingo",
    displayName: "(UTC-04:00) Atlantic Time (Santo Domingo)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/St_Barthelemy",
    displayName: "(UTC-04:00) Atlantic Time (St. Barthélemy)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/St_Kitts",
    displayName: "(UTC-04:00) Atlantic Time (St. Kitts)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/St_Lucia",
    displayName: "(UTC-04:00) Atlantic Time (St. Lucia)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/St_Thomas",
    displayName: "(UTC-04:00) Atlantic Time (St. Thomas)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/St_Vincent",
    displayName: "(UTC-04:00) Atlantic Time (St. Vincent)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Thule",
    displayName: "(UTC-04:00) Atlantic Time (Thule)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/Tortola",
    displayName: "(UTC-04:00) Atlantic Time (Tortola)",
    standardName: "Atlantic Standard Time",
    daylightName: "Atlantic Daylight Time"
  },
  {
    id: "America/La_Paz",
    displayName: "(UTC-04:00) Bolivia Time",
    standardName: "Bolivia Time",
    daylightName: "GMT-04:00"
  },
  {
    id: "America/Santiago",
    displayName: "(UTC-04:00) Chile Time",
    standardName: "Chile Standard Time",
    daylightName: "Chile Summer Time"
  },
  {
    id: "America/Guyana",
    displayName: "(UTC-04:00) Guyana Time",
    standardName: "Guyana Time",
    daylightName: "GMT-04:00"
  },
  {
    id: "America/Asuncion",
    displayName: "(UTC-04:00) Paraguay Time",
    standardName: "Paraguay Standard Time",
    daylightName: "Paraguay Summer Time"
  },
  {
    id: "America/Caracas",
    displayName: "(UTC-04:00) Venezuela Time",
    standardName: "Venezuela Time",
    daylightName: "GMT-04:00"
  },
  {
    id: "America/St_Johns",
    displayName: "(UTC-03:30) Newfoundland Time (St. John’s)",
    standardName: "Newfoundland Standard Time",
    daylightName: "Newfoundland Daylight Time"
  },
  {
    id: "America/Argentina/Buenos_Aires",
    displayName: "(UTC-03:00) Argentina Standard Time (Buenos Aires)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Argentina/Catamarca",
    displayName: "(UTC-03:00) Argentina Standard Time (Catamarca)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Argentina/Cordoba",
    displayName: "(UTC-03:00) Argentina Standard Time (Cordoba)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Argentina/Jujuy",
    displayName: "(UTC-03:00) Argentina Standard Time (Jujuy)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Argentina/La_Rioja",
    displayName: "(UTC-03:00) Argentina Standard Time (La Rioja)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Argentina/Mendoza",
    displayName: "(UTC-03:00) Argentina Standard Time (Mendoza)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Argentina/Rio_Gallegos",
    displayName: "(UTC-03:00) Argentina Standard Time (Rio Gallegos)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Argentina/Salta",
    displayName: "(UTC-03:00) Argentina Standard Time (Salta)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Argentina/San_Juan",
    displayName: "(UTC-03:00) Argentina Standard Time (San Juan)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Argentina/San_Luis",
    displayName: "(UTC-03:00) Argentina Standard Time (San Luis)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Argentina/Tucuman",
    displayName: "(UTC-03:00) Argentina Standard Time (Tucuman)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Argentina/Ushuaia",
    displayName: "(UTC-03:00) Argentina Standard Time (Ushuaia)",
    standardName: "Argentina Standard Time",
    daylightName: "Argentina Summer Time"
  },
  {
    id: "America/Araguaina",
    displayName: "(UTC-03:00) Brasilia Standard Time (Araguaina)",
    standardName: "Brasilia Standard Time",
    daylightName: "Brasilia Summer Time"
  },
  {
    id: "America/Bahia",
    displayName: "(UTC-03:00) Brasilia Standard Time (Bahia)",
    standardName: "Brasilia Standard Time",
    daylightName: "Brasilia Summer Time"
  },
  {
    id: "America/Belem",
    displayName: "(UTC-03:00) Brasilia Standard Time (Belem)",
    standardName: "Brasilia Standard Time",
    daylightName: "Brasilia Summer Time"
  },
  {
    id: "America/Fortaleza",
    displayName: "(UTC-03:00) Brasilia Standard Time (Fortaleza)",
    standardName: "Brasilia Standard Time",
    daylightName: "Brasilia Summer Time"
  },
  {
    id: "America/Maceio",
    displayName: "(UTC-03:00) Brasilia Standard Time (Maceio)",
    standardName: "Brasilia Standard Time",
    daylightName: "Brasilia Summer Time"
  },
  {
    id: "America/Recife",
    displayName: "(UTC-03:00) Brasilia Standard Time (Recife)",
    standardName: "Brasilia Standard Time",
    daylightName: "Brasilia Summer Time"
  },
  {
    id: "America/Santarem",
    displayName: "(UTC-03:00) Brasilia Standard Time (Santarem)",
    standardName: "Brasilia Standard Time",
    daylightName: "Brasilia Summer Time"
  },
  {
    id: "America/Sao_Paulo",
    displayName: "(UTC-03:00) Brasilia Standard Time (Sao Paulo)",
    standardName: "Brasilia Standard Time",
    daylightName: "Brasilia Summer Time"
  },
  {
    id: "Atlantic/Stanley",
    displayName: "(UTC-03:00) Falkland Islands Standard Time (Stanley)",
    standardName: "Falkland Islands Standard Time",
    daylightName: "Falkland Islands Summer Time"
  },
  {
    id: "America/Cayenne",
    displayName: "(UTC-03:00) French Guiana Time",
    standardName: "French Guiana Time",
    daylightName: "GMT-03:00"
  },
  {
    id: "America/Nuuk",
    displayName: "(UTC-03:00) GMT",
    standardName: "GMT",
    daylightName: "GMT"
  },
  {
    id: "Antarctica/Palmer",
    displayName: "(UTC-03:00) Palmer Time",
    standardName: "GMT-03:00",
    daylightName: "GMT-03:00"
  },
  {
    id: "America/Punta_Arenas",
    displayName: "(UTC-03:00) Punta Arenas Time",
    standardName: "GMT-03:00",
    daylightName: "GMT-03:00"
  },
  {
    id: "Antarctica/Rothera",
    displayName: "(UTC-03:00) Rothera Time",
    standardName: "Rothera Time",
    daylightName: "GMT-03:00"
  },
  {
    id: "America/Miquelon",
    displayName: "(UTC-03:00) St. Pierre & Miquelon Time",
    standardName: "St. Pierre & Miquelon Standard Time",
    daylightName: "St. Pierre & Miquelon Daylight Time"
  },
  {
    id: "America/Paramaribo",
    displayName: "(UTC-03:00) Suriname Time",
    standardName: "Suriname Time",
    daylightName: "GMT-03:00"
  },
  {
    id: "America/Montevideo",
    displayName: "(UTC-03:00) Uruguay Standard Time (Montevideo)",
    standardName: "Uruguay Standard Time",
    daylightName: "Uruguay Summer Time"
  },
  {
    id: "America/Noronha",
    displayName: "(UTC-02:00) Fernando de Noronha Standard Time",
    standardName: "Fernando de Noronha Standard Time",
    daylightName: "Fernando de Noronha Summer Time"
  },
  {
    id: "Atlantic/South_Georgia",
    displayName: "(UTC-02:00) South Georgia Time",
    standardName: "South Georgia Time",
    daylightName: "GMT-02:00"
  },
  {
    id: "Atlantic/Azores",
    displayName: "(UTC-01:00) Azores Time",
    standardName: "Azores Standard Time",
    daylightName: "Azores Summer Time"
  },
  {
    id: "Atlantic/Cape_Verde",
    displayName: "(UTC-01:00) Cape Verde Standard Time",
    standardName: "Cape Verde Standard Time",
    daylightName: "Cape Verde Summer Time"
  },
  {
    id: "America/Scoresbysund",
    displayName: "(UTC-01:00) East Greenland Time (Ittoqqortoormiit)",
    standardName: "East Greenland Standard Time",
    daylightName: "East Greenland Summer Time"
  },
  {
    id: "UTC",
    displayName: "(UTC) Coordinated Universal Time",
    standardName: "Coordinated Universal Time",
    daylightName: "Coordinated Universal Time"
  },
  {
    id: "Africa/Ouagadougou",
    displayName: "(UTC+00:00) Burkina Faso Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Africa/Abidjan",
    displayName: "(UTC+00:00) Côte d’Ivoire Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "America/Danmarkshavn",
    displayName: "(UTC+00:00) Danmarkshavn Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Africa/Banjul",
    displayName: "(UTC+00:00) Gambia Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Africa/Accra",
    displayName: "(UTC+00:00) Ghana Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Europe/Guernsey",
    displayName: "(UTC+00:00) Guernsey Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT+01:00"
  },
  {
    id: "Africa/Conakry",
    displayName: "(UTC+00:00) Guinea Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Africa/Bissau",
    displayName: "(UTC+00:00) Guinea-Bissau Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Atlantic/Reykjavik",
    displayName: "(UTC+00:00) Iceland Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Europe/Isle_of_Man",
    displayName: "(UTC+00:00) Isle of Man Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT+01:00"
  },
  {
    id: "Europe/Jersey",
    displayName: "(UTC+00:00) Jersey Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT+01:00"
  },
  {
    id: "Africa/Monrovia",
    displayName: "(UTC+00:00) Liberia Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Africa/Bamako",
    displayName: "(UTC+00:00) Mali Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Africa/Nouakchott",
    displayName: "(UTC+00:00) Mauritania Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Africa/Dakar",
    displayName: "(UTC+00:00) Senegal Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Africa/Freetown",
    displayName: "(UTC+00:00) Sierra Leone Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Atlantic/St_Helena",
    displayName: "(UTC+00:00) St. Helena Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Africa/Sao_Tome",
    displayName: "(UTC+00:00) São Tomé & Príncipe Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Africa/Lome",
    displayName: "(UTC+00:00) Togo Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT"
  },
  {
    id: "Antarctica/Troll",
    displayName: "(UTC+00:00) Troll Time",
    standardName: "Greenwich Mean Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Europe/London",
    displayName: "(UTC+00:00) United Kingdom Time",
    standardName: "Greenwich Mean Time",
    daylightName: "British Summer Time"
  },
  {
    id: "Atlantic/Canary",
    displayName: "(UTC+00:00) Western European Time (Canary)",
    standardName: "Western European Standard Time",
    daylightName: "Western European Summer Time"
  },
  {
    id: "Atlantic/Faroe",
    displayName: "(UTC+00:00) Western European Time (Faroe)",
    standardName: "Western European Standard Time",
    daylightName: "Western European Summer Time"
  },
  {
    id: "Europe/Lisbon",
    displayName: "(UTC+00:00) Western European Time (Lisbon)",
    standardName: "Western European Standard Time",
    daylightName: "Western European Summer Time"
  },
  {
    id: "Atlantic/Madeira",
    displayName: "(UTC+00:00) Western European Time (Madeira)",
    standardName: "Western European Standard Time",
    daylightName: "Western European Summer Time"
  },
  {
    id: "Africa/Algiers",
    displayName: "(UTC+01:00) Central European Time (Algiers)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Amsterdam",
    displayName: "(UTC+01:00) Central European Time (Amsterdam)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Andorra",
    displayName: "(UTC+01:00) Central European Time (Andorra)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Belgrade",
    displayName: "(UTC+01:00) Central European Time (Belgrade)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Berlin",
    displayName: "(UTC+01:00) Central European Time (Berlin)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Bratislava",
    displayName: "(UTC+01:00) Central European Time (Bratislava)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Brussels",
    displayName: "(UTC+01:00) Central European Time (Brussels)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Budapest",
    displayName: "(UTC+01:00) Central European Time (Budapest)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Busingen",
    displayName: "(UTC+01:00) Central European Time (Busingen)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Africa/Ceuta",
    displayName: "(UTC+01:00) Central European Time (Ceuta)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Copenhagen",
    displayName: "(UTC+01:00) Central European Time (Copenhagen)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Gibraltar",
    displayName: "(UTC+01:00) Central European Time (Gibraltar)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Ljubljana",
    displayName: "(UTC+01:00) Central European Time (Ljubljana)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Arctic/Longyearbyen",
    displayName: "(UTC+01:00) Central European Time (Longyearbyen)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Luxembourg",
    displayName: "(UTC+01:00) Central European Time (Luxembourg)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Madrid",
    displayName: "(UTC+01:00) Central European Time (Madrid)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Malta",
    displayName: "(UTC+01:00) Central European Time (Malta)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Monaco",
    displayName: "(UTC+01:00) Central European Time (Monaco)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Oslo",
    displayName: "(UTC+01:00) Central European Time (Oslo)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Paris",
    displayName: "(UTC+01:00) Central European Time (Paris)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Podgorica",
    displayName: "(UTC+01:00) Central European Time (Podgorica)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Prague",
    displayName: "(UTC+01:00) Central European Time (Prague)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Rome",
    displayName: "(UTC+01:00) Central European Time (Rome)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/San_Marino",
    displayName: "(UTC+01:00) Central European Time (San Marino)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Sarajevo",
    displayName: "(UTC+01:00) Central European Time (Sarajevo)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Skopje",
    displayName: "(UTC+01:00) Central European Time (Skopje)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Stockholm",
    displayName: "(UTC+01:00) Central European Time (Stockholm)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Tirane",
    displayName: "(UTC+01:00) Central European Time (Tirane)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Africa/Tunis",
    displayName: "(UTC+01:00) Central European Time (Tunis)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Vaduz",
    displayName: "(UTC+01:00) Central European Time (Vaduz)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Vatican",
    displayName: "(UTC+01:00) Central European Time (Vatican)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Vienna",
    displayName: "(UTC+01:00) Central European Time (Vienna)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Warsaw",
    displayName: "(UTC+01:00) Central European Time (Warsaw)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Zagreb",
    displayName: "(UTC+01:00) Central European Time (Zagreb)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Zurich",
    displayName: "(UTC+01:00) Central European Time (Zurich)",
    standardName: "Central European Standard Time",
    daylightName: "Central European Summer Time"
  },
  {
    id: "Europe/Dublin",
    displayName: "(UTC+01:00) Ireland Time",
    standardName: "Greenwich Mean Time",
    daylightName: "Irish Standard Time"
  },
  {
    id: "Africa/Casablanca",
    displayName: "(UTC+01:00) Morocco Time",
    standardName: "GMT",
    daylightName: "GMT+01:00"
  },
  {
    id: "Africa/Bangui",
    displayName: "(UTC+01:00) West Africa Standard Time (Bangui)",
    standardName: "West Africa Standard Time",
    daylightName: "West Africa Summer Time"
  },
  {
    id: "Africa/Brazzaville",
    displayName: "(UTC+01:00) West Africa Standard Time (Brazzaville)",
    standardName: "West Africa Standard Time",
    daylightName: "West Africa Summer Time"
  },
  {
    id: "Africa/Douala",
    displayName: "(UTC+01:00) West Africa Standard Time (Douala)",
    standardName: "West Africa Standard Time",
    daylightName: "West Africa Summer Time"
  },
  {
    id: "Africa/Kinshasa",
    displayName: "(UTC+01:00) West Africa Standard Time (Kinshasa)",
    standardName: "West Africa Standard Time",
    daylightName: "West Africa Summer Time"
  },
  {
    id: "Africa/Lagos",
    displayName: "(UTC+01:00) West Africa Standard Time (Lagos)",
    standardName: "West Africa Standard Time",
    daylightName: "West Africa Summer Time"
  },
  {
    id: "Africa/Libreville",
    displayName: "(UTC+01:00) West Africa Standard Time (Libreville)",
    standardName: "West Africa Standard Time",
    daylightName: "West Africa Summer Time"
  },
  {
    id: "Africa/Luanda",
    displayName: "(UTC+01:00) West Africa Standard Time (Luanda)",
    standardName: "West Africa Standard Time",
    daylightName: "West Africa Summer Time"
  },
  {
    id: "Africa/Malabo",
    displayName: "(UTC+01:00) West Africa Standard Time (Malabo)",
    standardName: "West Africa Standard Time",
    daylightName: "West Africa Summer Time"
  },
  {
    id: "Africa/Ndjamena",
    displayName: "(UTC+01:00) West Africa Standard Time (Ndjamena)",
    standardName: "West Africa Standard Time",
    daylightName: "West Africa Summer Time"
  },
  {
    id: "Africa/Niamey",
    displayName: "(UTC+01:00) West Africa Standard Time (Niamey)",
    standardName: "West Africa Standard Time",
    daylightName: "West Africa Summer Time"
  },
  {
    id: "Africa/Porto-Novo",
    displayName: "(UTC+01:00) West Africa Standard Time (Porto-Novo)",
    standardName: "West Africa Standard Time",
    daylightName: "West Africa Summer Time"
  },
  {
    id: "Africa/El_Aaiun",
    displayName: "(UTC+01:00) Western Sahara Time",
    standardName: "GMT",
    daylightName: "GMT+01:00"
  },
  {
    id: "Africa/Blantyre",
    displayName: "(UTC+02:00) Central Africa Time (Blantyre)",
    standardName: "Central Africa Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Bujumbura",
    displayName: "(UTC+02:00) Central Africa Time (Bujumbura)",
    standardName: "Central Africa Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Gaborone",
    displayName: "(UTC+02:00) Central Africa Time (Gaborone)",
    standardName: "Central Africa Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Harare",
    displayName: "(UTC+02:00) Central Africa Time (Harare)",
    standardName: "Central Africa Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Khartoum",
    displayName: "(UTC+02:00) Central Africa Time (Khartoum)",
    standardName: "Central Africa Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Kigali",
    displayName: "(UTC+02:00) Central Africa Time (Kigali)",
    standardName: "Central Africa Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Lubumbashi",
    displayName: "(UTC+02:00) Central Africa Time (Lubumbashi)",
    standardName: "Central Africa Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Lusaka",
    displayName: "(UTC+02:00) Central Africa Time (Lusaka)",
    standardName: "Central Africa Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Maputo",
    displayName: "(UTC+02:00) Central Africa Time (Maputo)",
    standardName: "Central Africa Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Windhoek",
    displayName: "(UTC+02:00) Central Africa Time (Windhoek)",
    standardName: "Central Africa Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Juba",
    displayName: "(UTC+02:00) East Africa Time (Juba)",
    standardName: "East Africa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Europe/Athens",
    displayName: "(UTC+02:00) Eastern European Time (Athens)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Asia/Beirut",
    displayName: "(UTC+02:00) Eastern European Time (Beirut)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Bucharest",
    displayName: "(UTC+02:00) Eastern European Time (Bucharest)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Africa/Cairo",
    displayName: "(UTC+02:00) Eastern European Time (Cairo)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Chisinau",
    displayName: "(UTC+02:00) Eastern European Time (Chisinau)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Asia/Gaza",
    displayName: "(UTC+02:00) Eastern European Time (Gaza)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Asia/Hebron",
    displayName: "(UTC+02:00) Eastern European Time (Hebron)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Helsinki",
    displayName: "(UTC+02:00) Eastern European Time (Helsinki)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Kaliningrad",
    displayName: "(UTC+02:00) Eastern European Time (Kaliningrad)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Kiev",
    displayName: "(UTC+02:00) Eastern European Time (Kiev)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Mariehamn",
    displayName: "(UTC+02:00) Eastern European Time (Mariehamn)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Asia/Nicosia",
    displayName: "(UTC+02:00) Eastern European Time (Nicosia)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Riga",
    displayName: "(UTC+02:00) Eastern European Time (Riga)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Sofia",
    displayName: "(UTC+02:00) Eastern European Time (Sofia)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Tallinn",
    displayName: "(UTC+02:00) Eastern European Time (Tallinn)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Africa/Tripoli",
    displayName: "(UTC+02:00) Eastern European Time (Tripoli)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Uzhgorod",
    displayName: "(UTC+02:00) Eastern European Time (Uzhhorod)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Vilnius",
    displayName: "(UTC+02:00) Eastern European Time (Vilnius)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Zaporozhye",
    displayName: "(UTC+02:00) Eastern European Time (Zaporozhye)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Asia/Famagusta",
    displayName: "(UTC+02:00) Famagusta Time",
    standardName: "GMT+02:00",
    daylightName: "GMT+03:00"
  },
  {
    id: "Asia/Jerusalem",
    displayName: "(UTC+02:00) Israel Time",
    standardName: "Israel Standard Time",
    daylightName: "Israel Daylight Time"
  },
  {
    id: "Africa/Johannesburg",
    displayName: "(UTC+02:00) South Africa Standard Time (Johannesburg)",
    standardName: "South Africa Standard Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Maseru",
    displayName: "(UTC+02:00) South Africa Standard Time (Maseru)",
    standardName: "South Africa Standard Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Africa/Mbabane",
    displayName: "(UTC+02:00) South Africa Standard Time (Mbabane)",
    standardName: "South Africa Standard Time",
    daylightName: "GMT+02:00"
  },
  {
    id: "Asia/Aden",
    displayName: "(UTC+03:00) Arabian Standard Time (Aden)",
    standardName: "Arabian Standard Time",
    daylightName: "Arabian Daylight Time"
  },
  {
    id: "Asia/Baghdad",
    displayName: "(UTC+03:00) Arabian Standard Time (Baghdad)",
    standardName: "Arabian Standard Time",
    daylightName: "Arabian Daylight Time"
  },
  {
    id: "Asia/Bahrain",
    displayName: "(UTC+03:00) Arabian Standard Time (Bahrain)",
    standardName: "Arabian Standard Time",
    daylightName: "Arabian Daylight Time"
  },
  {
    id: "Asia/Kuwait",
    displayName: "(UTC+03:00) Arabian Standard Time (Kuwait)",
    standardName: "Arabian Standard Time",
    daylightName: "Arabian Daylight Time"
  },
  {
    id: "Asia/Qatar",
    displayName: "(UTC+03:00) Arabian Standard Time (Qatar)",
    standardName: "Arabian Standard Time",
    daylightName: "Arabian Daylight Time"
  },
  {
    id: "Asia/Riyadh",
    displayName: "(UTC+03:00) Arabian Standard Time (Riyadh)",
    standardName: "Arabian Standard Time",
    daylightName: "Arabian Daylight Time"
  },
  {
    id: "Europe/Minsk",
    displayName: "(UTC+03:00) Belarus Time",
    standardName: "Moscow Standard Time",
    daylightName: "Moscow Summer Time"
  },
  {
    id: "Africa/Addis_Ababa",
    displayName: "(UTC+03:00) East Africa Time (Addis Ababa)",
    standardName: "East Africa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Indian/Antananarivo",
    displayName: "(UTC+03:00) East Africa Time (Antananarivo)",
    standardName: "East Africa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Africa/Asmara",
    displayName: "(UTC+03:00) East Africa Time (Asmara)",
    standardName: "East Africa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Indian/Comoro",
    displayName: "(UTC+03:00) East Africa Time (Comoro)",
    standardName: "East Africa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Africa/Dar_es_Salaam",
    displayName: "(UTC+03:00) East Africa Time (Dar es Salaam)",
    standardName: "East Africa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Africa/Djibouti",
    displayName: "(UTC+03:00) East Africa Time (Djibouti)",
    standardName: "East Africa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Africa/Kampala",
    displayName: "(UTC+03:00) East Africa Time (Kampala)",
    standardName: "East Africa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Indian/Mayotte",
    displayName: "(UTC+03:00) East Africa Time (Mayotte)",
    standardName: "East Africa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Africa/Mogadishu",
    displayName: "(UTC+03:00) East Africa Time (Mogadishu)",
    standardName: "East Africa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Africa/Nairobi",
    displayName: "(UTC+03:00) East Africa Time (Nairobi)",
    standardName: "East Africa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Asia/Amman",
    displayName: "(UTC+03:00) Eastern European Time (Amman)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Asia/Damascus",
    displayName: "(UTC+03:00) Eastern European Time (Damascus)",
    standardName: "Eastern European Standard Time",
    daylightName: "Eastern European Summer Time"
  },
  {
    id: "Europe/Kirov",
    displayName: "(UTC+03:00) Kirov Time",
    standardName: "GMT+03:00",
    daylightName: "GMT+03:00"
  },
  {
    id: "Europe/Moscow",
    displayName: "(UTC+03:00) Moscow Time",
    standardName: "Moscow Standard Time",
    daylightName: "Moscow Summer Time"
  },
  {
    id: "Europe/Simferopol",
    displayName: "(UTC+03:00) Simferopol Time",
    standardName: "Moscow Standard Time",
    daylightName: "Moscow Summer Time"
  },
  {
    id: "Antarctica/Syowa",
    displayName: "(UTC+03:00) Syowa Time",
    standardName: "Syowa Time",
    daylightName: "GMT+03:00"
  },
  {
    id: "Europe/Istanbul",
    displayName: "(UTC+03:00) Turkey Time",
    standardName: "GMT+03:00",
    daylightName: "GMT+03:00"
  },
  {
    id: "Europe/Volgograd",
    displayName: "(UTC+03:00) Volgograd Standard Time",
    standardName: "Volgograd Standard Time",
    daylightName: "Volgograd Summer Time"
  },
  {
    id: "Asia/Tehran",
    displayName: "(UTC+03:30) Iran Time",
    standardName: "Iran Standard Time",
    daylightName: "Iran Daylight Time"
  },
  {
    id: "Asia/Yerevan",
    displayName: "(UTC+04:00) Armenia Standard Time (Yerevan)",
    standardName: "Armenia Standard Time",
    daylightName: "Armenia Summer Time"
  },
  {
    id: "Europe/Astrakhan",
    displayName: "(UTC+04:00) Astrakhan Time",
    standardName: "GMT+04:00",
    daylightName: "GMT+04:00"
  },
  {
    id: "Asia/Baku",
    displayName: "(UTC+04:00) Azerbaijan Standard Time (Baku)",
    standardName: "Azerbaijan Standard Time",
    daylightName: "Azerbaijan Summer Time"
  },
  {
    id: "Asia/Tbilisi",
    displayName: "(UTC+04:00) Georgia Standard Time (Tbilisi)",
    standardName: "Georgia Standard Time",
    daylightName: "Georgia Summer Time"
  },
  {
    id: "Asia/Dubai",
    displayName: "(UTC+04:00) Gulf Standard Time (Dubai)",
    standardName: "Gulf Standard Time",
    daylightName: "GMT+04:00"
  },
  {
    id: "Asia/Muscat",
    displayName: "(UTC+04:00) Gulf Standard Time (Muscat)",
    standardName: "Gulf Standard Time",
    daylightName: "GMT+04:00"
  },
  {
    id: "Indian/Mauritius",
    displayName: "(UTC+04:00) Mauritius Standard Time",
    standardName: "Mauritius Standard Time",
    daylightName: "Mauritius Summer Time"
  },
  {
    id: "Indian/Reunion",
    displayName: "(UTC+04:00) Réunion Time",
    standardName: "Réunion Time",
    daylightName: "GMT+04:00"
  },
  {
    id: "Europe/Samara",
    displayName: "(UTC+04:00) Samara Standard Time",
    standardName: "Samara Standard Time",
    daylightName: "Samara Summer Time"
  },
  {
    id: "Europe/Saratov",
    displayName: "(UTC+04:00) Saratov Time",
    standardName: "GMT+04:00",
    daylightName: "GMT+04:00"
  },
  {
    id: "Indian/Mahe",
    displayName: "(UTC+04:00) Seychelles Time",
    standardName: "Seychelles Time",
    daylightName: "GMT+04:00"
  },
  {
    id: "Europe/Ulyanovsk",
    displayName: "(UTC+04:00) Ulyanovsk Time",
    standardName: "GMT+04:00",
    daylightName: "GMT+04:00"
  },
  {
    id: "Asia/Kabul",
    displayName: "(UTC+04:30) Afghanistan Time",
    standardName: "Afghanistan Time",
    daylightName: "GMT+04:30"
  },
  {
    id: "Indian/Kerguelen",
    displayName: "(UTC+05:00) French Southern & Antarctic Time (Kerguelen)",
    standardName: "French Southern & Antarctic Time",
    daylightName: "GMT+05:00"
  },
  {
    id: "Indian/Maldives",
    displayName: "(UTC+05:00) Maldives Time",
    standardName: "Maldives Time",
    daylightName: "GMT+05:00"
  },
  {
    id: "Antarctica/Mawson",
    displayName: "(UTC+05:00) Mawson Time",
    standardName: "Mawson Time",
    daylightName: "GMT+05:00"
  },
  {
    id: "Asia/Karachi",
    displayName: "(UTC+05:00) Pakistan Standard Time (Karachi)",
    standardName: "Pakistan Standard Time",
    daylightName: "Pakistan Summer Time"
  },
  {
    id: "Asia/Dushanbe",
    displayName: "(UTC+05:00) Tajikistan Time",
    standardName: "Tajikistan Time",
    daylightName: "GMT+05:00"
  },
  {
    id: "Asia/Ashgabat",
    displayName: "(UTC+05:00) Turkmenistan Standard Time (Ashgabat)",
    standardName: "Turkmenistan Standard Time",
    daylightName: "Turkmenistan Summer Time"
  },
  {
    id: "Asia/Samarkand",
    displayName: "(UTC+05:00) Uzbekistan Standard Time (Samarkand)",
    standardName: "Uzbekistan Standard Time",
    daylightName: "Uzbekistan Summer Time"
  },
  {
    id: "Asia/Tashkent",
    displayName: "(UTC+05:00) Uzbekistan Standard Time (Tashkent)",
    standardName: "Uzbekistan Standard Time",
    daylightName: "Uzbekistan Summer Time"
  },
  {
    id: "Asia/Aqtau",
    displayName: "(UTC+05:00) West Kazakhstan Time (Aqtau)",
    standardName: "West Kazakhstan Time",
    daylightName: "GMT+05:00"
  },
  {
    id: "Asia/Aqtobe",
    displayName: "(UTC+05:00) West Kazakhstan Time (Aqtobe)",
    standardName: "West Kazakhstan Time",
    daylightName: "GMT+05:00"
  },
  {
    id: "Asia/Atyrau",
    displayName: "(UTC+05:00) West Kazakhstan Time (Atyrau)",
    standardName: "West Kazakhstan Time",
    daylightName: "GMT+05:00"
  },
  {
    id: "Asia/Oral",
    displayName: "(UTC+05:00) West Kazakhstan Time (Oral)",
    standardName: "West Kazakhstan Time",
    daylightName: "GMT+05:00"
  },
  {
    id: "Asia/Qyzylorda",
    displayName: "(UTC+05:00) West Kazakhstan Time (Qyzylorda)",
    standardName: "West Kazakhstan Time",
    daylightName: "GMT+05:00"
  },
  {
    id: "Asia/Yekaterinburg",
    displayName: "(UTC+05:00) Yekaterinburg Standard Time",
    standardName: "Yekaterinburg Standard Time",
    daylightName: "Yekaterinburg Summer Time"
  },
  {
    id: "Asia/Colombo",
    displayName: "(UTC+05:30) India Standard Time (Colombo)",
    standardName: "India Standard Time",
    daylightName: "GMT+05:30"
  },
  {
    id: "Asia/Kolkata",
    displayName: "(UTC+05:30) India Standard Time (Kolkata)",
    standardName: "India Standard Time",
    daylightName: "GMT+05:30"
  },
  {
    id: "Asia/Kathmandu",
    displayName: "(UTC+05:45) Nepal Time",
    standardName: "Nepal Time",
    daylightName: "GMT+05:45"
  },
  {
    id: "Asia/Dhaka",
    displayName: "(UTC+06:00) Bangladesh Standard Time (Dhaka)",
    standardName: "Bangladesh Standard Time",
    daylightName: "Bangladesh Summer Time"
  },
  {
    id: "Asia/Thimphu",
    displayName: "(UTC+06:00) Bhutan Time",
    standardName: "Bhutan Time",
    daylightName: "GMT+06:00"
  },
  {
    id: "Asia/Almaty",
    displayName: "(UTC+06:00) East Kazakhstan Time (Almaty)",
    standardName: "East Kazakhstan Time",
    daylightName: "GMT+06:00"
  },
  {
    id: "Asia/Qostanay",
    displayName: "(UTC+06:00) East Kazakhstan Time (Kostanay)",
    standardName: "East Kazakhstan Time",
    daylightName: "GMT+06:00"
  },
  {
    id: "Indian/Chagos",
    displayName: "(UTC+06:00) Indian Ocean Time (Chagos)",
    standardName: "Indian Ocean Time",
    daylightName: "GMT+06:00"
  },
  {
    id: "Asia/Bishkek",
    displayName: "(UTC+06:00) Kyrgyzstan Time",
    standardName: "Kyrgyzstan Time",
    daylightName: "GMT+06:00"
  },
  {
    id: "Asia/Omsk",
    displayName: "(UTC+06:00) Omsk Standard Time",
    standardName: "Omsk Standard Time",
    daylightName: "Omsk Summer Time"
  },
  {
    id: "Asia/Urumqi",
    displayName: "(UTC+06:00) Urumqi Time",
    standardName: "GMT+06:00",
    daylightName: "GMT+06:00"
  },
  {
    id: "Antarctica/Vostok",
    displayName: "(UTC+06:00) Vostok Time",
    standardName: "Vostok Time",
    daylightName: "GMT+06:00"
  },
  {
    id: "Indian/Cocos",
    displayName: "(UTC+06:30) Cocos Islands Time",
    standardName: "Cocos Islands Time",
    daylightName: "GMT+06:30"
  },
  {
    id: "Asia/Yangon",
    displayName: "(UTC+06:30) Myanmar Time (Yangon)",
    standardName: "Myanmar Time",
    daylightName: "GMT+06:30"
  },
  {
    id: "Asia/Barnaul",
    displayName: "(UTC+07:00) Barnaul Time",
    standardName: "GMT+07:00",
    daylightName: "GMT+07:00"
  },
  {
    id: "Indian/Christmas",
    displayName: "(UTC+07:00) Christmas Island Time",
    standardName: "Christmas Island Time",
    daylightName: "GMT+07:00"
  },
  {
    id: "Antarctica/Davis",
    displayName: "(UTC+07:00) Davis Time",
    standardName: "Davis Time",
    daylightName: "GMT+07:00"
  },
  {
    id: "Asia/Hovd",
    displayName: "(UTC+07:00) Hovd Standard Time",
    standardName: "Hovd Standard Time",
    daylightName: "Hovd Summer Time"
  },
  {
    id: "Asia/Bangkok",
    displayName: "(UTC+07:00) Indochina Time (Bangkok)",
    standardName: "Indochina Time",
    daylightName: "GMT+07:00"
  },
  {
    id: "Asia/Ho_Chi_Minh",
    displayName: "(UTC+07:00) Indochina Time (Ho Chi Minh City)",
    standardName: "Indochina Time",
    daylightName: "GMT+07:00"
  },
  {
    id: "Asia/Phnom_Penh",
    displayName: "(UTC+07:00) Indochina Time (Phnom Penh)",
    standardName: "Indochina Time",
    daylightName: "GMT+07:00"
  },
  {
    id: "Asia/Vientiane",
    displayName: "(UTC+07:00) Indochina Time (Vientiane)",
    standardName: "Indochina Time",
    daylightName: "GMT+07:00"
  },
  {
    id: "Asia/Krasnoyarsk",
    displayName: "(UTC+07:00) Krasnoyarsk Standard Time",
    standardName: "Krasnoyarsk Standard Time",
    daylightName: "Krasnoyarsk Summer Time"
  },
  {
    id: "Asia/Novokuznetsk",
    displayName: "(UTC+07:00) Krasnoyarsk Standard Time (Novokuznetsk)",
    standardName: "Krasnoyarsk Standard Time",
    daylightName: "Krasnoyarsk Summer Time"
  },
  {
    id: "Asia/Novosibirsk",
    displayName: "(UTC+07:00) Novosibirsk Standard Time",
    standardName: "Novosibirsk Standard Time",
    daylightName: "Novosibirsk Summer Time"
  },
  {
    id: "Asia/Tomsk",
    displayName: "(UTC+07:00) Tomsk Time",
    standardName: "GMT+07:00",
    daylightName: "GMT+07:00"
  },
  {
    id: "Asia/Jakarta",
    displayName: "(UTC+07:00) Western Indonesia Time (Jakarta)",
    standardName: "Western Indonesia Time",
    daylightName: "GMT+07:00"
  },
  {
    id: "Asia/Pontianak",
    displayName: "(UTC+07:00) Western Indonesia Time (Pontianak)",
    standardName: "Western Indonesia Time",
    daylightName: "GMT+07:00"
  },
  {
    id: "Australia/Perth",
    displayName: "(UTC+08:00) Australian Western Standard Time (Perth)",
    standardName: "Australian Western Standard Time",
    daylightName: "Australian Western Daylight Time"
  },
  {
    id: "Asia/Brunei",
    displayName: "(UTC+08:00) Brunei Darussalam Time",
    standardName: "Brunei Darussalam Time",
    daylightName: "GMT+08:00"
  },
  {
    id: "Asia/Makassar",
    displayName: "(UTC+08:00) Central Indonesia Time (Makassar)",
    standardName: "Central Indonesia Time",
    daylightName: "GMT+08:00"
  },
  {
    id: "Asia/Macau",
    displayName: "(UTC+08:00) China Standard Time (Macao)",
    standardName: "China Standard Time",
    daylightName: "China Daylight Time"
  },
  {
    id: "Asia/Shanghai",
    displayName: "(UTC+08:00) China Standard Time (Shanghai)",
    standardName: "China Standard Time",
    daylightName: "China Daylight Time"
  },
  {
    id: "Asia/Hong_Kong",
    displayName: "(UTC+08:00) Hong Kong Standard Time",
    standardName: "Hong Kong Standard Time",
    daylightName: "Hong Kong Summer Time"
  },
  {
    id: "Asia/Irkutsk",
    displayName: "(UTC+08:00) Irkutsk Standard Time",
    standardName: "Irkutsk Standard Time",
    daylightName: "Irkutsk Summer Time"
  },
  {
    id: "Asia/Kuala_Lumpur",
    displayName: "(UTC+08:00) Malaysia Time",
    standardName: "Malaysia Time",
    daylightName: "GMT+08:00"
  },
  {
    id: "Asia/Kuching",
    displayName: "(UTC+08:00) Malaysia Time (Kuching)",
    standardName: "Malaysia Time",
    daylightName: "GMT+08:00"
  },
  {
    id: "Asia/Manila",
    displayName: "(UTC+08:00) Philippine Standard Time (Manila)",
    standardName: "Philippine Standard Time",
    daylightName: "Philippine Summer Time"
  },
  {
    id: "Asia/Singapore",
    displayName: "(UTC+08:00) Singapore Standard Time",
    standardName: "Singapore Standard Time",
    daylightName: "GMT+08:00"
  },
  {
    id: "Asia/Taipei",
    displayName: "(UTC+08:00) Taipei Standard Time",
    standardName: "Taipei Standard Time",
    daylightName: "Taipei Daylight Time"
  },
  {
    id: "Asia/Ulaanbaatar",
    displayName: "(UTC+08:00) Ulaanbaatar Standard Time",
    standardName: "Ulaanbaatar Standard Time",
    daylightName: "Ulaanbaatar Summer Time"
  },
  {
    id: "Asia/Choibalsan",
    displayName: "(UTC+08:00) Ulaanbaatar Standard Time (Choibalsan)",
    standardName: "Ulaanbaatar Standard Time",
    daylightName: "Ulaanbaatar Summer Time"
  },
  {
    id: "Australia/Eucla",
    displayName: "(UTC+08:45) Australian Central Western Standard Time (Eucla)",
    standardName: "Australian Central Western Standard Time",
    daylightName: "Australian Central Western Daylight Time"
  },
  {
    id: "Asia/Dili",
    displayName: "(UTC+09:00) East Timor Time (Dili)",
    standardName: "East Timor Time",
    daylightName: "GMT+09:00"
  },
  {
    id: "Asia/Jayapura",
    displayName: "(UTC+09:00) Eastern Indonesia Time (Jayapura)",
    standardName: "Eastern Indonesia Time",
    daylightName: "GMT+09:00"
  },
  {
    id: "Asia/Tokyo",
    displayName: "(UTC+09:00) Japan Standard Time (Tokyo)",
    standardName: "Japan Standard Time",
    daylightName: "Japan Daylight Time"
  },
  {
    id: "Asia/Pyongyang",
    displayName: "(UTC+09:00) Korean Standard Time (Pyongyang)",
    standardName: "Korean Standard Time",
    daylightName: "Korean Daylight Time"
  },
  {
    id: "Asia/Seoul",
    displayName: "(UTC+09:00) Korean Standard Time (Seoul)",
    standardName: "Korean Standard Time",
    daylightName: "Korean Daylight Time"
  },
  {
    id: "Pacific/Palau",
    displayName: "(UTC+09:00) Palau Time",
    standardName: "Palau Time",
    daylightName: "GMT+09:00"
  },
  {
    id: "Asia/Yakutsk",
    displayName: "(UTC+09:00) Yakutsk Standard Time",
    standardName: "Yakutsk Standard Time",
    daylightName: "Yakutsk Summer Time"
  },
  {
    id: "Asia/Chita",
    displayName: "(UTC+09:00) Yakutsk Standard Time (Chita)",
    standardName: "Yakutsk Standard Time",
    daylightName: "Yakutsk Summer Time"
  },
  {
    id: "Asia/Khandyga",
    displayName: "(UTC+09:00) Yakutsk Standard Time (Khandyga)",
    standardName: "Yakutsk Standard Time",
    daylightName: "Yakutsk Summer Time"
  },
  {
    id: "Australia/Adelaide",
    displayName: "(UTC+09:30) Central Australia Time (Adelaide)",
    standardName: "Australian Central Standard Time",
    daylightName: "Australian Central Daylight Time"
  },
  {
    id: "Australia/Broken_Hill",
    displayName: "(UTC+09:30) Central Australia Time (Broken Hill)",
    standardName: "Australian Central Standard Time",
    daylightName: "Australian Central Daylight Time"
  },
  {
    id: "Australia/Darwin",
    displayName: "(UTC+09:30) Central Australia Time (Darwin)",
    standardName: "Australian Central Standard Time",
    daylightName: "Australian Central Daylight Time"
  },
  {
    id: "Pacific/Guam",
    displayName: "(UTC+10:00) Chamorro Standard Time (Guam)",
    standardName: "Chamorro Standard Time",
    daylightName: "GMT+10:00"
  },
  {
    id: "Pacific/Saipan",
    displayName: "(UTC+10:00) Chamorro Standard Time (Saipan)",
    standardName: "Chamorro Standard Time",
    daylightName: "GMT+10:00"
  },
  {
    id: "Pacific/Chuuk",
    displayName: "(UTC+10:00) Chuuk Time",
    standardName: "Chuuk Time",
    daylightName: "GMT+10:00"
  },
  {
    id: "Antarctica/DumontDUrville",
    displayName: "(UTC+10:00) Dumont-d’Urville Time (Dumont d’Urville)",
    standardName: "Dumont-d’Urville Time",
    daylightName: "GMT+10:00"
  },
  {
    id: "Australia/Brisbane",
    displayName: "(UTC+10:00) Eastern Australia Time (Brisbane)",
    standardName: "Australian Eastern Standard Time",
    daylightName: "Australian Eastern Daylight Time"
  },
  {
    id: "Australia/Hobart",
    displayName: "(UTC+10:00) Eastern Australia Time (Hobart)",
    standardName: "Australian Eastern Standard Time",
    daylightName: "Australian Eastern Daylight Time"
  },
  {
    id: "Australia/Lindeman",
    displayName: "(UTC+10:00) Eastern Australia Time (Lindeman)",
    standardName: "Australian Eastern Standard Time",
    daylightName: "Australian Eastern Daylight Time"
  },
  {
    id: "Australia/Melbourne",
    displayName: "(UTC+10:00) Eastern Australia Time (Melbourne)",
    standardName: "Australian Eastern Standard Time",
    daylightName: "Australian Eastern Daylight Time"
  },
  {
    id: "Australia/Sydney",
    displayName: "(UTC+10:00) Eastern Australia Time (Sydney)",
    standardName: "Australian Eastern Standard Time",
    daylightName: "Australian Eastern Daylight Time"
  },
  {
    id: "Antarctica/Macquarie",
    displayName: "(UTC+10:00) Macquarie Island Time",
    standardName: "Macquarie Island Time",
    daylightName: "GMT+11:00"
  },
  {
    id: "Pacific/Port_Moresby",
    displayName: "(UTC+10:00) Papua New Guinea Time (Port Moresby)",
    standardName: "Papua New Guinea Time",
    daylightName: "GMT+10:00"
  },
  {
    id: "Asia/Vladivostok",
    displayName: "(UTC+10:00) Vladivostok Standard Time",
    standardName: "Vladivostok Standard Time",
    daylightName: "Vladivostok Summer Time"
  },
  {
    id: "Asia/Ust-Nera",
    displayName: "(UTC+10:00) Vladivostok Standard Time (Ust-Nera)",
    standardName: "Vladivostok Standard Time",
    daylightName: "Vladivostok Summer Time"
  },
  {
    id: "Australia/Lord_Howe",
    displayName: "(UTC+10:30) Lord Howe Time",
    standardName: "Lord Howe Standard Time",
    daylightName: "Lord Howe Daylight Time"
  },
  {
    id: "Antarctica/Casey",
    displayName: "(UTC+11:00) Australian Western Standard Time (Casey)",
    standardName: "Australian Western Standard Time",
    daylightName: "Australian Western Daylight Time"
  },
  {
    id: "Pacific/Bougainville",
    displayName: "(UTC+11:00) Bougainville Time",
    standardName: "GMT+11:00",
    daylightName: "GMT+11:00"
  },
  {
    id: "Pacific/Kosrae",
    displayName: "(UTC+11:00) Kosrae Time",
    standardName: "Kosrae Time",
    daylightName: "GMT+11:00"
  },
  {
    id: "Asia/Magadan",
    displayName: "(UTC+11:00) Magadan Standard Time",
    standardName: "Magadan Standard Time",
    daylightName: "Magadan Summer Time"
  },
  {
    id: "Pacific/Noumea",
    displayName: "(UTC+11:00) New Caledonia Standard Time (Noumea)",
    standardName: "New Caledonia Standard Time",
    daylightName: "New Caledonia Summer Time"
  },
  {
    id: "Pacific/Norfolk",
    displayName: "(UTC+11:00) Norfolk Island Time",
    standardName: "Norfolk Island Standard Time",
    daylightName: "Norfolk Island Daylight Time"
  },
  {
    id: "Pacific/Pohnpei",
    displayName: "(UTC+11:00) Ponape Time (Pohnpei)",
    standardName: "Ponape Time",
    daylightName: "GMT+11:00"
  },
  {
    id: "Asia/Sakhalin",
    displayName: "(UTC+11:00) Sakhalin Standard Time",
    standardName: "Sakhalin Standard Time",
    daylightName: "Sakhalin Summer Time"
  },
  {
    id: "Pacific/Guadalcanal",
    displayName: "(UTC+11:00) Solomon Islands Time",
    standardName: "Solomon Islands Time",
    daylightName: "GMT+11:00"
  },
  {
    id: "Asia/Srednekolymsk",
    displayName: "(UTC+11:00) Srednekolymsk Time",
    standardName: "GMT+11:00",
    daylightName: "GMT+11:00"
  },
  {
    id: "Pacific/Efate",
    displayName: "(UTC+11:00) Vanuatu Standard Time (Efate)",
    standardName: "Vanuatu Standard Time",
    daylightName: "Vanuatu Summer Time"
  },
  {
    id: "Asia/Anadyr",
    displayName: "(UTC+12:00) Anadyr Standard Time",
    standardName: "Anadyr Standard Time",
    daylightName: "Anadyr Summer Time"
  },
  {
    id: "Pacific/Fiji",
    displayName: "(UTC+12:00) Fiji Time",
    standardName: "Fiji Standard Time",
    daylightName: "Fiji Summer Time"
  },
  {
    id: "Pacific/Tarawa",
    displayName: "(UTC+12:00) Gilbert Islands Time (Tarawa)",
    standardName: "Gilbert Islands Time",
    daylightName: "GMT+12:00"
  },
  {
    id: "Pacific/Majuro",
    displayName: "(UTC+12:00) Marshall Islands Time",
    standardName: "Marshall Islands Time",
    daylightName: "GMT+12:00"
  },
  {
    id: "Pacific/Kwajalein",
    displayName: "(UTC+12:00) Marshall Islands Time (Kwajalein)",
    standardName: "Marshall Islands Time",
    daylightName: "GMT+12:00"
  },
  {
    id: "Pacific/Nauru",
    displayName: "(UTC+12:00) Nauru Time",
    standardName: "Nauru Time",
    daylightName: "GMT+12:00"
  },
  {
    id: "Pacific/Auckland",
    displayName: "(UTC+12:00) New Zealand Time",
    standardName: "New Zealand Standard Time",
    daylightName: "New Zealand Daylight Time"
  },
  {
    id: "Antarctica/McMurdo",
    displayName: "(UTC+12:00) New Zealand Time (McMurdo)",
    standardName: "New Zealand Standard Time",
    daylightName: "New Zealand Daylight Time"
  },
  {
    id: "Asia/Kamchatka",
    displayName: "(UTC+12:00) Petropavlovsk-Kamchatski Standard Time (Kamchatka)",
    standardName: "Petropavlovsk-Kamchatski Standard Time",
    daylightName: "Petropavlovsk-Kamchatski Summer Time"
  },
  {
    id: "Pacific/Funafuti",
    displayName: "(UTC+12:00) Tuvalu Time",
    standardName: "Tuvalu Time",
    daylightName: "GMT+12:00"
  },
  {
    id: "Pacific/Wake",
    displayName: "(UTC+12:00) Wake Island Time",
    standardName: "Wake Island Time",
    daylightName: "GMT+12:00"
  },
  {
    id: "Pacific/Wallis",
    displayName: "(UTC+12:00) Wallis & Futuna Time",
    standardName: "Wallis & Futuna Time",
    daylightName: "GMT+12:00"
  },
  {
    id: "Pacific/Chatham",
    displayName: "(UTC+12:45) Chatham Time",
    standardName: "Chatham Standard Time",
    daylightName: "Chatham Daylight Time"
  },
  {
    id: "Pacific/Enderbury",
    displayName: "(UTC+13:00) Phoenix Islands Time (Enderbury)",
    standardName: "Phoenix Islands Time",
    daylightName: "GMT+13:00"
  },
  {
    id: "Pacific/Apia",
    displayName: "(UTC+13:00) Samoa Time",
    standardName: "Apia Standard Time",
    daylightName: "Apia Daylight Time"
  },
  {
    id: "Pacific/Fakaofo",
    displayName: "(UTC+13:00) Tokelau Time",
    standardName: "Tokelau Time",
    daylightName: "GMT+13:00"
  },
  {
    id: "Pacific/Tongatapu",
    displayName: "(UTC+13:00) Tonga Standard Time (Tongatapu)",
    standardName: "Tonga Standard Time",
    daylightName: "Tonga Summer Time"
  },
  {
    id: "Pacific/Kiritimati",
    displayName: "(UTC+14:00) Line Islands Time (Kiritimati)",
    standardName: "Line Islands Time",
    daylightName: "GMT+14:00"
  }
];

export const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const accountTypesEnum = {
  // 1: 'Dueño',
  2: 'Técnico',
  3: 'Recepcionista',
} as any;

export const accountTypes = [
  // { value: 1, label: 'Dueño' },
  { value: 2, label: 'Técnico' },
  { value: 3, label: 'Recepcionista' },
];

export const sponsorTypes = [
  { value: 'star', label: 'Estrella' },
  { value: 'junior', label: 'Junior' },
];