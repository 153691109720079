import { AlertColor } from '@mui/material/Alert/Alert';
import { Subject, SubjectLike } from 'rxjs';
import { BaseSnackbarStateProps } from '../index';

const Service = () => {
  let state: BaseSnackbarStateProps;
  let subject: SubjectLike<any> = new Subject();

  const get = () => state;

  const show = (
    message: string,
    type: AlertColor = 'success',
    milliseconds = 5000,
  ) => new Promise((resolve: any) => {
    state = {
      message, type, milliseconds,
    }
    subject.next(state);
    resolve();
  });

  const subscribe = (process: any) => subject.subscribe(process);

  return {
    get,
    show,
    subscribe,
  }
};

export default Service();
