import React from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockIcon from "@mui/icons-material/Lock";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PublicLayout from "../../app/layouts/PublicLayout";
import { Button, TextField } from "../../app/config/controls";
import logo from "../../assets/img/logo_samii.png";
import { useAppDispatch } from "../../app/hooks";
import useStyles from "./styles";
import api from "./api";

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: yup.object({
      username: yup.string().required("Este campo es requerido"),
      password: yup.string().required("Este campo es requerido"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(api.login(values))
        .then(() => {
          setSubmitting(false);
          history.push("/app");
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <PublicLayout>
      <div className={classes.containerLogin}>
        <img src={logo} alt="" className={classes.logo} />
        <Box className={classes.login}>
          <h2 style={{ color: "#8B5CF6", fontWeight: "bold" }}>Bienvenido</h2>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="username"
                  placeholder="Usuario"
                  InputProps={{
                    startAdornment: (
                      <AccountCircleIcon fontSize="small" color="disabled" />
                    ),
                  }}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  error={formik.errors.username && formik.touched.username}
                  helperText={
                    formik.errors.username && formik.touched.username
                      ? formik.errors.username
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="password"
                  type="password"
                  placeholder="Contraseña"
                  InputProps={{
                    startAdornment: (
                      <LockIcon fontSize="small" color="disabled" />
                    ),
                  }}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  error={formik.errors.password && formik.touched.password}
                  helperText={
                    formik.errors.password && formik.touched.password
                      ? formik.errors.password
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" disabled={formik.isSubmitting}>
                  Iniciar Sesión
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </PublicLayout>
  );
};

export default Login;
