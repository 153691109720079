import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";

const Card = ({
  color,
  title,
  description,
  icon: Icon,
  onClick,
}: any) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} onClick={onClick}>
      <Box className={classes.body}>
        <Box>
          <Typography variant="h3" color={color} fontWeight="bold">
            {title}
          </Typography>
          <Typography fontSize={15} color="gray">
            {description}
          </Typography>
        </Box>
        <Box>
          <Icon />
        </Box>
      </Box>
      <Box className={classes.footer} style={{ backgroundColor: color }} />
    </Box>
  );
};

export default Card;
