import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Button } from "../../config/controls";
import { useAppSelector } from "../../hooks";
import api from "./api";

const statuses: any = {
  paid: {
    title: 'PAGADO',
    backgroundColor: '#26A271',
  },
  rejected: {
    title: 'RECHAZADO',
    backgroundColor: '#FF6781',
  },
  review: {
    title: 'EN REVISIÓN',
    backgroundColor: '#707070',
  },
};

const Badge = ({ title, backgroundColor, onClick }: any) => (
  <Box
    onClick={onClick}
    style={{
      cursor: 'pointer',
      backgroundColor,
      width: "fit-content",
      padding: "4px 8px",
      color: "white",
      borderRadius: "4px",
      fontSize: "12px",
      marginRight: '8px',
    }}
  >
    {title}
  </Box>
);

const SponsorPayments = forwardRef(({ action, showAttachments, ...props }: any, ref) => {
  const { user } = useAppSelector((state) => state);
  const sponsorId = props.sponsorId || user.sponsorId;
  const [rows, setRows] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleEvidenceClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEvidenceClose = () => {
    setAnchorEl(null);
  };

  const showFile = (file: string) => {
    window.open(`https://s3.amazonaws.com/${file}`, '_blank');
  };

  const handleStatusChange = (rowId: string, name: string) => {
    api.update({ sponsorPaymentRequestId: rowId, [name]: true }).then(() => {
      api.load({ sponsorId, page: 1, pageSize: 9999 }).then(setRows);
    });
  };

  useEffect(() => {
    api.load({ sponsorId, page: 1, pageSize: 9999 }).then(setRows);
  }, [sponsorId]);

  useImperativeHandle(ref, () => ({
    reload: () => {
      api.load({ sponsorId, page: 1, pageSize: 9999 }).then(setRows);
    },
  }));

  return (
    <Box>
      <Box
        width="100%"
        display="inline-flex"
        marginBottom="8px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontWeight="bold" fontSize="16px" color="grey">
          Listado de Mis Pagos
        </Typography>
        <Typography>
          {action}
        </Typography>
      </Box>
      <Box
        width="100%"
        display="inline-flex"
        marginBottom="8px"
        style={{ backgroundColor: "#8B5CF5" }}
      >
        <Grid container spacing={2} style={{ padding: "4px 24px" }}>
          <Grid item xs={9}>
            <Typography color="white">
              Mes
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="white">
              Estatus
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {rows.map((row) => {
        const { title, backgroundColor } = statuses[row.status];

        return (
          <Box key={row.id} width="100%" display="inline-flex" marginBottom="4px">
            <Grid container spacing={2} style={{ padding: "0 24px 4px" }}>
              <Grid item xs={showAttachments ? 3 : 9}>
                <Typography fontWeight="bold" fontSize="14px" color="grey">
                  {row.name}
                </Typography>
              </Grid>
              {showAttachments && (
                <>
                  <Grid item xs={3}>
                    <Button onClick={() => showFile(row.invoicePdf)}>
                      Ver Invoice
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Tooltip title="Ver Evidencias">
                      <span>
                        <Button
                          onClick={handleEvidenceClick}
                          size="small"
                          color="primary"
                          sx={{ ml: 2 }}
                          aria-controls={open ? "sponsor-evidences" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                        Ver Evidencias
                      </Button>
                      </span>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleEvidenceClose}
                      onClick={handleEvidenceClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem onClick={() => showFile(row.evidences.first)}>
                        Primera Evidencia
                      </MenuItem>
                      <MenuItem onClick={() => showFile(row.evidences.second)}>
                        Segunda Evidencia
                      </MenuItem>
                    </Menu>
                  </Grid>
                </>
              )}
              <Grid item xs={3}>
                {(showAttachments && row.status === 'review')  ? (
                  <Box width="100%" display="inline-flex">
                    <Badge backgroundColor="#26A271" title="Aceptar" onClick={() => handleStatusChange(row.id, 'isPaid')} />
                    <Badge backgroundColor="#FF6781" title="Rechazar" onClick={() => handleStatusChange(row.id, 'isRejected')} />
                  </Box>
                ) : (
                  <Badge backgroundColor={backgroundColor} title={title} />
                )}
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
});

export default SponsorPayments;
